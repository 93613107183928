import axios from "axios";
import React, { useEffect, useState } from "react";

const OtpVerify = () => {
    
    const [otpCode1, setOtpCode1] = useState("");
    const [otpCode2, setOtpCode2] = useState("");
    const [otpCode3, setOtpCode3] = useState("");
    const [otpCode4, setOtpCode4] = useState("");
    const [exportedJson, setExportedJson] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const jsonResult = {};
        queryParams.forEach((value, key) => {
            jsonResult[key] = value;
        });
        setExportedJson(jsonResult);
    }, []);

    const resendOtp = async () => {
        const payLoad = {
            "phone": exportedJson.phone ? exportedJson.phone : "",
            "client_id": exportedJson.client_id,
            "email": exportedJson.email ? exportedJson.email : "",
            "consent_template": exportedJson.template_id,
        }
        axios.post("https://econsent.tactionclients.com/api/peersupport/otpresendcode", payLoad)
            .then(res => {
                if (res.status == 200) {
                    alert(res?.data?.message);
                }   
                console.log(res);
            })
            .catch(err => {
                if (err.response?.data?.message) {
                    alert(err.response.data.message);
                }
                if (err.response?.data?.error) {
                    alert(err.response.data.error);
                }
                console.log(err);
            }
        )
    }

    const otpSubmission = async () => {
        const payLoad = {
            "phone": exportedJson.phone,
            "email": exportedJson.email,
            "otp_code": otpCode1 + otpCode2 + otpCode3 + otpCode4,
            "token": exportedJson.token
        }
        if (Object.keys(exportedJson).length > 0) {
            axios.post("https://econsent.tactionclients.com/api/peersupport/verifyotp", payLoad)
                .then(res => {
                    if (res.status == 200) {
                        alert(res.data.message);
                    }
                    console.log(res);
                    window.location.href = '/eConsentSignIn';
                })
                .catch(err => {
                    if (err.response) {
                        alert(err.response.data.error);
                    }
                    console.log(err);
                }
            )
        } else {
            alert("Something went wrong..!");
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="card p-4 text-center">
                <h2 style={{ color: "#89bc3b" }}>Verify OTP</h2>
                <p className="mt-4"><b>Please enter the 4-digit code sent to your E-mail or phone number.</b></p>
                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-3"><input type="text" maxLength={1} onChange={(e) => { setOtpCode1(e.target.value) }} value={otpCode1} style={{ height: '60px', borderRadius: '20px', width: '80px', textAlign: 'center', fontSize: '30px' }} name="otpValue1" id="otpValue1" /></div>
                    <div className="col-3"><input type="text" maxLength={1} onChange={(e) => { setOtpCode2(e.target.value) }} value={otpCode2} style={{ height: '60px', borderRadius: '20px', width: '80px', textAlign: 'center', fontSize: '30px' }} name="otpValue2" id="otpValue2" /></div>
                    <div className="col-3"><input type="text" maxLength={1} onChange={(e) => { setOtpCode3(e.target.value) }} value={otpCode3} style={{ height: '60px', borderRadius: '20px', width: '80px', textAlign: 'center', fontSize: '30px' }} name="otpValue3" id="otpValue3" /></div>
                    <div className="col-3"><input type="text" maxLength={1} onChange={(e) => { setOtpCode4(e.target.value) }} value={otpCode4} style={{ height: '60px', borderRadius: '20px', width: '80px', textAlign: 'center', fontSize: '30px' }} name="otpValue4" id="otpValue4" /></div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <input type="button" onClick={resendOtp} className="btn btn-primary" value="Resend OTP" />
                    <input type="submit" onClick={otpSubmission} className="btn btn-success" value="Verify" />
                </div>
            </div>
        </div>
    );
};

export default OtpVerify;