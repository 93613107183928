import React, { useEffect, useState } from "react";
import Modal from '../../Modals/Modal/index';
import Topnav from '../../Topnav';
import Footer from '../../Footer';
import { encryptStorage } from '../../LocalStorageInterceptor';
import { Button, Dropdown, ModalBody } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Patientinfoheader from '../../Patientinfoheader';
import Sidenav from '../../Sidenav';
import '../../../global.js';
import axios from "axios";
import { Alert } from "../../toastify";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import moment from "moment";

const FormEconsent = () => {

    const [formModal, setFormModal] = useState(false);
    const [consentDropdownOptions, setConsentDropdownOptions] = useState(null);
    const [communicationMethod, setCommunicationMethod] = useState("");
    const [contactValueMail, setContactValueMail] = useState(encryptStorage.getItem('email'));
    const [contactValuePhone, setContactValuePhone] = useState(encryptStorage.getItem('phone'));
    const [contactMethodError, setContactMethodError] = useState("");
    const [clientID, setClientId] = useState(sessionStorage.getItem('clientId'));
    const [clientName, setClientName] = useState(encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name'));
    const [consentFormType, setConsentFormType] = useState('');
    const [consentFormTypeError, setConsentFormTypeError] = useState('');
    const [rowData, setRowData] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
    const [clientfullName, setClientfullName] = useState({ client_name: "", consent_template: "" });

    const columns = [
        { name: 'Client ID', selector: row => row.Client_id, sortable: false },
        { name: 'Client Name', selector: row => row.Full_name, sortable: false },
        { name: 'Consent', selector: row => row.Consent?.name, sortable: false },
        { name: 'Email', selector: row => row.mail, sortable: false },
        { name: 'Phone No.', selector: row => row.Primary_phone, sortable: false },
        { name: 'Sent Date', selector: row => row.sent_date, sortable: false },
        { name: 'Validity Upto', selector: row => row.validityUpTo, sortable: false },
        { name: 'Sent by', selector: row => row.sent_by, sortable: false },
        { name: 'Status', selector: row => row.status, sortable: false },
        // { name: '', selector: (row, index) => <div className="d-flex align-item-center ml-4"><select className="btn btn-secondary btn-sm dropdown-toggle" onChange={(e) => { if (e.target.value === "Esign") { window.location.href = "/eConsentSignIn" } else { } }}><option value="">--Select</option><option value="Esign">eSign</option><option value="resend">Re-Send</option></select></div>, sortable: false }
        {
            name: '', selector: (row, index) => <div className="d-flex align-item-center ml-4" >
                {row.status === "Pending" ?
                    <select style={{ marginLeft: "-7px" }} className="btn btn-secondary btn-sm dropdown-toggle" onChange={(e) => handleStatusChange(e, row)}>
                        <option value="">Select</option>
                        <option value="Esign">eSign</option>
                        <option value="resend">Re-Send</option>
                    </select> : <button className="btn btn-primary" onClick={() => handleDownloadConsent(row)}>Download </button>
                }
            </div>, sortable: false
        }
    ];

    const handleStatusChange = (e, clientData) => {
        if (e.target.value === "Esign") {
            window.location.href = "/e-consent-signin";
        } else if (e.target.value === "resend") {
            resendOtpCodeOnly(clientData);
        }
    }

    const resendOtpCodeOnly = async (clientData) => {
        const data = {
            phone: clientData.Primary_phone,
            client_id: clientData.Client_id,
            email: clientData.mail || "",
            method: clientData.communication_method,
            consent_template : clientData.Consent?._id,
        };
        try {
            const response = await axios.post("https://econsent.tactionclients.com/api/peersupport/otpresendcode", JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }, });
            console.log("Form submitted successfully:", response);
            alert("OTP resent successfully!");
        } catch (error) {
            console.error("Error submitting form:", error.response || error.message);
            alert("Failed to send data.");
        }
    };

    const handleCheckBoxChange = (type) => {
        if(type === "eMail"){
            setContactValuePhone("");
            setContactValueMail(encryptStorage.getItem('email'));
        }
        if(type === "sms"){
            setContactValueMail("");
            setContactValuePhone(encryptStorage.getItem('phone'));
        }
        setCommunicationMethod(type)
    }

    const resendOtp = async (clientData) => {
        const data = {
            phone: clientData.Primary_phone,
            client_id: clientData.Client_id,
            email: clientData.mail || "",
            method: clientData.communication_method,
        };
        try {
            const response = await axios.post("https://econsent.tactionclients.com/api/peersupport/otpresend", JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }, });
            console.log("Form submitted successfully:", response);
            alert("OTP resent successfully!");
        } catch (error) {
            console.error("Error submitting form:", error.response || error.message);
            alert("Failed to send data.");
        }
    };


    const handleDownloadConsent = async (row) => {
        const payLoad = {
            "consent_template": row.Consent._id,
            "client_id" : sessionStorage.getItem('clientId')
        }
        // const consentFileUrl = "https://econsent.tactionclients.com/api/client/download/CL-20210805-0101";
        await axios.post("https://econsent.tactionclients.com/api/client/download", JSON.stringify(payLoad)).then(
            (res) => {
                // debugger
                if(res){
                    console.log(res)
                }
            }
        ).catch(
            (err) => {
                // debugger
                console.log(err.response)
            }
        );
        // const link = document.createElement('a');
        // Object.assign(link, {
        //     href: consentFileUrl,
        //     download: `Consent_${row.Client_id}.pdf`
        // });
        // link.click();
    };

    const changeFields = (e) => {
        if (communicationMethod === "sms") {
            setContactValueMail("");
            setContactValuePhone(e.target.value);
        } else if (communicationMethod === "eMail") {
            setContactValuePhone('');
            setContactValueMail(e.target.value);
        } else {
            alert("Please Select communication method");
        }
        // setContactValue(e.target.value);
    }

    const submitConsent = async () => {
        // https://econsent.tactionclients.com/api/peersupport/createconsent
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (contactValueMail === "" && communicationMethod === 'eMail') {
            setContactMethodError("Email is required.");
            return;
        } else if (communicationMethod === 'eMail' && !emailRegex.test(contactValueMail)) {
            setContactMethodError("Please enter a valid email address.");
            return;
        }
        if (contactValuePhone === "" && communicationMethod === 'sms') {
            setContactMethodError('Phone no. is required');
            return;
        } else if (contactValuePhone.length < 10 && communicationMethod === 'sms') {
            setContactMethodError('Please fill a correct phone no.');
            return;
        }
        if (consentFormType === "") {
            setConsentFormTypeError("This field is Required.");
            return;
        }
        let payLoad = {
            client_id: sessionStorage.getItem('clientId'),
            client_name: encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name'),
            email: contactValueMail,
            phone: contactValuePhone,
            peer_support_specialist: encryptStorage.getItem('peer_support_specialist'),
            consent_template: consentFormType,
            communication_method: communicationMethod.toLowerCase(),
            organization_id: '676e703e0d4d8ce657257d0e'
        }
        // try {
        await axios.post("https://econsent.tactionclients.com/api/peersupport/createconsent", payLoad).then(
            (response) => {
                if (response.data.success === true) {
                    // debugger
                    // fetchDataList();
                    fetchDataList();
                    setFormModal(false);
                    Alert("success", response.data.msg);
                    console.log(response.data.data);
                }
            }
        ).catch(
            (error) => {
                Alert("error", "There is a some problem in consent creation. Please try again.");
                console.error(error.message);
            }
        );
        // } catch (error) {
        //     console.error('Error fetching templates:', error.message);
        // }
    }

    const consentType = (e) => {
        setConsentFormTypeError("");
        setConsentFormType(e.target.value);
    }

    useEffect(
        () => {
            getTemplate();
            fetchDataList();
        }, []
    );

    const getTemplate = async () => {
        try {
            const response = await axios.get("https://econsent.tactionclients.com/consenttemplates/list", { params: { user: sessionStorage.getItem('loggedinUserID') } });
            setConsentDropdownOptions(response.data.consentForms);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }

    const fetchDataList = async () => {
        try {
            // debugger
            // const fetchedData = await axios.get('https://econsent.tactionclients.com/api/peersupport/getconsent');
            const fetchedData = await axios.post('https://econsent.tactionclients.com/api/peersupport/get-econsent-by-clientid', { "client_id": sessionStorage.getItem('clientId')});
            console.log(fetchedData, "fetchedDatafetchedDatafetchedDatafetchedData")
            const mappedData = fetchedData.data.data.map((item) => ({
                Client_id: item.client_id,
                Full_name: item.client_name,
                Consent: item.consent_template,
                mail: item.email,
                Primary_phone: item.phone,
                sent_date: item.send_date,
                Primary_phone: item.phone,
                validityUpTo: item.validity_date,
                sent_by: item.peer_support_specialist,
                status: item.status,
                communication_method: item.communication_method
            }));
            setRowData(mappedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleApply = (event, picker) => {
        setDateRange({
            startDate: picker.startDate.format("MM/DD/YYYY"),
            endDate: picker.endDate.format("MM/DD/YYYY"),
        });

    };


    const handleSearch = async () => {
        const { startDate, endDate } = dateRange
        const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
        const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

        const payloadData = { ...clientfullName, startDate: formattedStartDate, endDate: formattedEndDate }

        try {
            const response = await axios.post('https://econsent.tactionclients.com/api/peersupport/filter', JSON.stringify(payloadData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const mappedData = response.data.data.map((item) => ({
                Client_id: item.client_id,
                Full_name: item.client_name,
                Consent: item.consent_template.name,
                mail: item.email,
                Primary_phone: item.phone,
                sent_date: item.send_date,
                Primary_phone: item.phone,
                validityUpTo: item.validity_date,
                sent_by: item.peer_support_specialist,
                status: item.status,
                communication_method: item.communication_method
            }));
            setRowData(mappedData);
        } catch (error) {
            console.error("Error occurred during POST request:", error); // Log any errors
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target
        setClientfullName((preval) => { return { ...preval, [name]: value } });
    }

    const handleReset = () => {
        fetchDataList();
        setClientfullName({ client_name: "", consent_template: "" });
        setDateRange({ startDate: "", endDate: "" });

    }

    return (
        <>
            {formModal ?
                <Modal
                    style={{ width: "800px" }}
                    showOverlay={true}
                    show={true}
                    onClose={() => { setFormModal(false) }}
                    disableOverlayClick={true}
                    showCloseButton={true}
                    size="sm"
                >
                    <Modal.Header>
                        <Modal.Title style={{ textAlign: "center" }}>
                            <h4 className="text-green">eConsent Form</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p-5">
                            <div className="row">
                                <div className="col-6 mt-2">
                                    <label value="Consent Form">Consent Form <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-6 float-right">
                                    <select className="form-control" onChange={(e) => { consentType(e) }} value={consentFormType}>
                                        <option value="">-- Select --</option>
                                        {consentDropdownOptions?.map((opts) => <option value={opts._id}>{opts.name}</option>)}
                                    </select>
                                    {consentFormTypeError === "" ? "" : <span style={{ color: 'red' }}>This field is required.</span>}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 mt-2">
                                    <label value="Consent Form">Cient ID</label>
                                </div>
                                <div className="col-6 float-right">
                                    <input type="text" readOnly className="form-control" placeholder="CL-00000000-0000" name="clientId" id="clientId" value={clientID}></input>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 mt-2">
                                    <label value="Consent Form">Full Name</label>
                                </div>
                                <div className="col-6 float-right">
                                    <input type="text" className="form-control" disabled placeholder="TechEsperto Softwares" name="clientName" id="clientName" value={clientName}></input>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 mt-2">
                                    <label value="Consent Form">Contact {communicationMethod === "eMail" ? 'mail' : 'number'}</label>
                                </div>
                                <div className="col-6 float-right">
                                    <input type="text" readOnly className="form-control" placeholder={communicationMethod === "eMail" ? "@.comxyz" : '(000) 000-0000'} name="contactMethod" id="contactMethod" onChange={(e) => { changeFields(e) }} value={communicationMethod === "eMail" ? contactValueMail : contactValuePhone}></input>
                                    {contactMethodError !== "" ? <span style={{ color: 'red' }}>{contactMethodError}</span> : ""}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 mt-2">
                                    <label value="Consent Form">Communication Method</label>
                                </div>
                                <div className="col-6 float-right d-flex justify-content-between align-items-center">
                                    <div className="col-6">
                                        <input type="checkbox" id="communicationMethod" name="communicationMethod" onChange={() => { handleCheckBoxChange("sms") } } checked={communicationMethod === "sms"} /> <label htmlFor="sms_method" className="ml-2">SMS</label>
                                    </div>
                                    <div className="col-6">
                                        <input type="checkbox" name="communicationMethod" id="communicationMethod" onChange={() => { handleCheckBoxChange("eMail") } } checked={communicationMethod === "eMail"} /> <label htmlFor="mail_method" className="ml-2">E-mail</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="justify-content-between">
                            <Button className="btn-default float-left" onClick={() => { setFormModal(false) }} type="button" form="myForm" key="submit" htmlType="submit">Close</Button>
                        </div>
                        <div className="row float-right">
                            {/* <Button className='btn btn-success' onClick={() => { alert('eConsent successfully sent.'); setFormModal(false) }} type="button" key="submit" htmlType="submit">Send</Button> */}
                            <button id="Open" type="submit" className="btn btn-success float-right btnmargin" form="AuthorizationForm" onClick={submitConsent}> <i className="fas fa-save"></i> Save</button>
                        </div>
                        {/* </Modal.Title> */}
                    </Modal.Footer>

                </Modal> : ""
            }
            {/* </div> */}
            <div className="hold-transition sidebar-mini text-sm">
                {/* <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} /> */}
                <ReactTooltip place="right" type="dark" effect="solid" />
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">
                        <Patientinfoheader
                            clientId={sessionStorage.getItem('clientId')}
                            dob={encryptStorage.getItem('dob')}
                            email={encryptStorage.getItem('email')}
                            mask="(999) 999-9999" phone={encryptStorage.getItem('phone')}
                            peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
                            next_followup_date={encryptStorage.getItem('month_1_followup_date')}
                            intake_date={encryptStorage.getItem('intake_date')}
                            initiated_by={encryptStorage.getItem('last_updated_by')}
                            formname={sessionStorage.getItem('formname')}
                            client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
                        />
                        <section className="content">
                            <div className="row">
                                <Sidenav />
                                <div className="container-fluid col-md-9 col-lg-10">
                                    <div className="card-header" >
                                        <h3 className="card-title"><b>eConsent Templates</b></h3>
                                        {/* <Button variant="secondary" onClick={() => { window.location.href = '/consentOtp' }} className="btn btn-success d-flex align-items-center ml-5">
                                                    <h5> <i className="fas fa-user-plus"></i>&nbsp; eConsent for client </h5>
                                                </Button> */}
                                        <Button variant="secondary" onClick={() => { setFormModal(true) }} className="btn btn-success float-right d-flex align-items-center">
                                            <h5> <i className="fas fa-user-plus"></i>&nbsp; Create eConsent</h5>
                                        </Button>
                                    </div>
                                    {/* <button className="btn btn-success ml-3 mt-2" variant="secondary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        Search Bar
                                    </button> */}
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="collapse ml-3" id="collapseExample" style={{ width: "79vw" }}>
                                                <div className="card card-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4">
                                                            <label className="form-label">Date Range</label>
                                                            <DateRangePicker onApply={handleApply}>
                                                                <input style={{ backgroundColor: "transparent" }} type="text" className="form-control" placeholder="Select date range" readOnly value={dateRange.startDate && dateRange.endDate ? `${dateRange.startDate} - ${dateRange.endDate}` : ""} />
                                                            </DateRangePicker>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="form-label">Client Name</label>
                                                            <input type="text" className="form-control" placeholder="Enter client name" name="client_name" value={clientfullName.client_name} onChange={handleChange} />
                                                        </div>

                                                        {/* Consent Form */}
                                                        <div className="col-md-3">
                                                            <label className="form-label">Consent Form</label>
                                                            <select className="form-control" value={clientfullName.consent_template} name="consent_template" onChange={handleChange} >
                                                                <option value="">Select</option>
                                                                <option value="Explicit Consent">Explicit Consent</option>
                                                                <option value="Implied Consent">Implied Consent</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label d-block" style={{ visibility: "hidden" }}>
                                                                Search
                                                            </label>
                                                            <button className="btn btn-success w-100" onClick={handleSearch}> Search </button>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label d-block" style={{ visibility: "hidden" }}>
                                                                Reset
                                                            </label>
                                                            <button className="btn btn-success w-100" onClick={handleReset}>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            data={rowData}
                                            // onRowClicked={this.handleRowClicked}
                                            customStyles={global.customTableStyles}
                                            progressPending={false}
                                            striped={true}
                                            // pagination
                                            paginationComponentOptions={global.paginationComponentOptions}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                        // onSort={handleSort}
                                        // sortFunction={customSort}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
};

export default FormEconsent;








// import React, { useEffect, useState } from "react";
// import Sidenav from '../../Sidenav';
// import { encryptStorage } from '../../LocalStorageInterceptor';
// import Topnav from '../../Topnav';
// import axios from "axios";
// import Patientinfoheader from '../../Patientinfoheader';
// import { Alert } from "../../toastify";


// const FormEconsent = () => {

//     const [consentDropdownOptions, setConsentDropdownOptions] = useState(null);
//     const [formModal, setFormModal] = useState(false);
//     const [communicationMethod, setCommunicationMethod] = useState("");
//     const [contactValueMail, setContactValueMail] = useState(encryptStorage.getItem('email'));
//     const [contactValuePhone, setContactValuePhone] = useState(encryptStorage.getItem('phone'));
//     const [consentFormType, setConsentFormType] = useState('');
//     const [contactMethodError, setContactMethodError] = useState("");
//     const [clientID, setClientId] = useState(sessionStorage.getItem('clientId'));
//     const [clientName, setClientName] = useState(encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name'));
//     const [consentFormTypeError, setConsentFormTypeError] = useState('');


//     useEffect(
//         () => {
//             getTemplate();
//             // fetchDataList();
//         }, []
//     );

//     const submitConsent = async () => {
//         // https://econsent.tactionclients.com/api/peersupport/createconsent
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (contactValueMail === "" && communicationMethod === 'eMail') {
//             setContactMethodError("Email is required.");
//             return;
//         } else if (communicationMethod === 'eMail' && !emailRegex.test(contactValueMail)) {
//             setContactMethodError("Please enter a valid email address.");
//             return;
//         }
//         if (contactValuePhone === "" && communicationMethod === 'sms') {
//             setContactMethodError('Phone no. is required');
//             return;
//         } else if (contactValuePhone.length < 10 && communicationMethod === 'sms') {
//             setContactMethodError('Please fill a correct phone no.');
//             return;
//         }
//         if (consentFormType === "") {
//             setConsentFormTypeError("This field is Required.");
//             return;
//         }
//         let payLoad = {
//             client_id: sessionStorage.getItem('clientId'),
//             client_name: encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name'),
//             email: contactValueMail,
//             phone: contactValuePhone,
//             peer_support_specialist: encryptStorage.getItem('peer_support_specialist'),
//             consent_template: consentFormType,
//             communication_method: communicationMethod.toLowerCase(),
//             organization_id: '676e703e0d4d8ce657257d0e'
//         }
//         // try {
//         await axios.post("https://econsent.tactionclients.com/api/peersupport/createconsent", payLoad).then(
//             (response) => {
//                 if (response.data.success === true) {
//                     // setFormModal(false);
//                     Alert("success", response.data.msg);
//                     console.log(response.data.data);
//                 }
//             }
//         ).catch(
//             (error) => {
//                 Alert("error", "There is a some problem in consent creation. Please try again.");
//                 console.error(error.message);
//             }
//         );
//         // } catch (error) {
//         //     console.error('Error fetching templates:', error.message);
//         // }
//     }

//     const changeFields = (e) => {
//         if (communicationMethod === "sms") {
//             setContactValueMail("");
//             setContactValuePhone(e.target.value);
//         } else if (communicationMethod === "eMail") {
//             setContactValuePhone('');
//             setContactValueMail(e.target.value);
//         } else {
//             alert("Please Select communication method");
//         }
//         // setContactValue(e.target.value);
//     }

//     const consentType = (e) => {
//         setConsentFormTypeError("");
//         setConsentFormType(e.target.value);
//     }

//     const getTemplate = async () => {
//         try {
//             const response = await axios.get("https://econsent.tactionclients.com/consenttemplates/list", { params: { user: sessionStorage.getItem('loggedinUserID') } });
//             setConsentDropdownOptions(response.data.consentForms);
//         } catch (error) {
//             console.error('Error fetching templates:', error);
//         }
//     }

//     return (

//         <>
//             <div className="hold-transition sidebar-mini text-sm">
//                 <div className="wrapper">
//                     <Topnav />
//                     <Patientinfoheader
//                         clientId={sessionStorage.getItem('clientId')}
//                         dob={encryptStorage.getItem('dob')}
//                         email={encryptStorage.getItem('email')}
//                         mask="(999) 999-9999" phone={encryptStorage.getItem('phone')}
//                         peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
//                         next_followup_date={encryptStorage.getItem('month_1_followup_date')}
//                         intake_date={encryptStorage.getItem('intake_date')}
//                         initiated_by={encryptStorage.getItem('last_updated_by')}
//                         formname={sessionStorage.getItem('formname')}
//                         client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
//                     />
//                     {/* <Sidenav /> */}
//                     <div className="row p-3">
//                         <Sidenav />
//                         <div className="container-fluid col-md-9 col-lg-10">
//                             <div className="card-header">
//                                 <h4 className="text-green float-left">eConsent Form</h4>
//                                 <button id="Open" type="submit" className="btn btn-success float-right" form="AuthorizationForm" onClick={submitConsent}> <i className="fas fa-save"></i> Save</button>
//                             </div>
//                             <div className="card-body">
//                                 <div className="row mt-3">
//                                     <div className="col-6">
//                                         <label value="Consent Form">Consent Form <span style={{ color: 'red' }}>*</span></label>
//                                     </div>
//                                     <div className="col-6">
//                                         <select className="form-control" onChange={(e) => { consentType(e) }} value={consentFormType}>
//                                             <option value="">-- Select --</option>
//                                             {consentDropdownOptions?.map((opts) => <option value={opts._id}>{opts.name}</option>)}
//                                         </select>
//                                         {consentFormTypeError === "" ? "" : <span style={{ color: 'red' }}>This field is required.</span>}
//                                     </div>
//                                 </div>
//                                 <div className="row mt-3">
//                                     <div className="col-6 mt-2">
//                                         <label value="Consent Form">Cient ID</label>
//                                     </div>
//                                     <div className="col-6">
//                                         <input type="text" className="form-control" readOnly placeholder="CL-00000000-0000" name="clientId" id="clientId" value={clientID}></input>
//                                     </div>
//                                 </div>
//                                 <div className="row align-items-start mt-4">
//                                     <div className="col-6 mt-2">
//                                         <label value="Consent Form">Full Name</label>
//                                     </div>
//                                     <div className="col-6">
//                                         <input type="text" className="form-control" disabled placeholder="TechEsperto Softwares" name="clientName" id="clientName" value={clientName}></input>
//                                     </div>
//                                 </div>
//                                 <div className="row align-items-start mt-4">
//                                     <div className="col-6 mt-2">
//                                         <label value="Consent Form">Contact {communicationMethod === "eMail" ? 'mail' : 'number'}</label>
//                                     </div>
//                                     <div className="col-6">
//                                         <input type="text" className="form-control" readOnly placeholder={communicationMethod === "eMail" ? "@.comxyz" : '(000) 000-0000'} name="contactMethod" id="contactMethod" onChange={(e) => { changeFields(e) }} value={communicationMethod === "eMail" ? contactValueMail : contactValuePhone}></input>
//                                         {contactMethodError !== "" ? <span style={{ color: 'red' }}>{contactMethodError}</span> : ""}
//                                     </div>
//                                 </div>
//                                 <div className="row align-items-start mt-4">
//                                     <div className="col-6 mt-2">
//                                         <label value="Consent Form">Communication Method</label>
//                                     </div>
//                                     <div className="col-6 float-right d-flex justify-content-between align-items-center">
//                                         <div className="col-6">
//                                             <input type="checkbox" id="communicationMethod" name="communicationMethod" onChange={() => { setCommunicationMethod("sms") }} checked={communicationMethod === "sms"} /> <label htmlFor="sms_method" className="ml-2">SMS</label>
//                                         </div>
//                                         <div className="col-6">
//                                             <input type="checkbox" name="communicationMethod" id="communicationMethod" onChange={() => { setCommunicationMethod("eMail") }} checked={communicationMethod === "eMail"} /> <label htmlFor="mail_method" className="ml-2">E-mail</label>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="card-footer">
//                                 <button id="Open" type="submit" className="btn btn-success float-right" form="AuthorizationForm" onClick={submitConsent}> <i className="fas fa-save"></i> Save</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default FormEconsent;
