import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import '../global.js';

import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { has_access_to } from '../ReusableComponents/urlGenerator';

const options = {
  title: 'Leave this page',
  message: 'Do you want to save your progress?',
  buttons: [
    {
      label: 'Yes',
      onClick: () => alert('Click Yes')
    },
    {
      label: 'No',
      onClick: () => alert('Click No')
    }
  ],
  childrenElement: () => <div />,
  customUI: ({ onClose }) => <div className='custom_alert_msg_ui'>
    <h1>Leave this page</h1>
    <p>Do you want to save your progress?</p>
    <button onClick={onClose}>No</button>
    <button onClick={() => {
      onClose()
    }}>Yes, Save it!</button>
  </div>,
  closeOnEscape: true,
  closeOnClickOutside: true,
  willUnmount: () => { },
  afterClose: () => { },
  onClickOutside: () => { },
  onKeypressEscape: () => { }
};

class Sidenav extends Component {

  componentDidMount() {


    $(document).ready(function () {

      //disable left menu if new client's basic information is not saved
      $('body').on('click', '.sidenav a.nav-link', function (e) {

        let this_href = $(this).attr('href');
        //  if (sessionStorage.getItem('clientId') === '') {
        //   // alert('form not saved');
        //   e.preventDefault();
        // }
          if (sessionStorage.getItem('changes_unsaved')) {

          } else {
            window.location.href = this.href;
          }
      });


    });

    function sidebar_nav() {


      var uri_arr = document.location.pathname.split('/');
      var php_file_name = '/' + uri_arr[uri_arr.length - 1];
      console.log('current from sidebar', php_file_name)
      $('a.nav-link').each(function () {
        console.log('looping', $(this))
        if ($(this).attr('href') == php_file_name) {
          $(this.parentNode).addClass('active action2');
          $(this).addClass('active action2');

        }
      });
      $("a.nav-link").each(function () {
        var uri_arr = document.location.pathname.split('/');
        var php_file_name = '/' + uri_arr[uri_arr.length - 1];

        if ($(this).attr('href') == php_file_name) {
          $(this).parent().parent().siblings('a').addClass('to-expand');
          checkParent($(this).parent().parent());
        }
      });


      setTimeout(function () {
        console.log('triggering setTimeout from sidebar');
        $("a.to-expand").each(function () {
          console.log('triggering click from sidebar', $(this), $(this).trigger('click'))
          $(this).trigger('click');
          $(this).removeClass('to-expand');
        });
      }, 100);
    }
    function checkParent(obj) {
      console.log('checkParent called from sidebar')
      var obj2 = obj.parent().parent('ul');
      if (obj2.length) {
        obj2.siblings('a').addClass('to-expand');
        checkParent(obj2);
      } else {
        obj.siblings('a').addClass('active');
        return;
      }
    }

  }

  render() {
    return (
      <div className="col-md-3 col-lg-2 sidenav">
        <ul className="nav nav-pills nav-sidebar flex-column nav-compact text-sm" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item" id="gpra" >
            <div className="card">
              <NavLink to="/dashboard" className="nav-link" id="gpra_a">
                <div className="card-body p-0">
                  <i className="fas fa-arrow-left"></i> Client Dashboard
                </div>
              </NavLink>
            </div>
          </li>
          <li id="intake" className="nav-item has-treeview" >
            <div className="row" style={{ paddingLeft: "4px" }}>
              <div className="col-md-12 col-lg-12">
                <div className="text-green"> <span>Client Forms</span></div>

              </div>
            </div>
            <div className="card">
              <a href="javascript:void(0)" className="nav-link" id="intake_a">
                <div className="card-body p-0">
                  <i className="fas fa-hospital"></i> Intake
                  <i className="float-right fas fa-angle-left"></i>
                </div>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink to="/eConsent-from" className="nav-link">
                    <div className="card-body p-0">
                      eConsent
                    </div>
                  </NavLink>
                </li>
              </ul>
              <ul className="nav nav-treeview has-treeview" id="basic_info">
                {has_access_to(['ClientInformation', 'EmergencyContact']) ? (<li className="nav-item">
                  <a href="javascript:void(0)" className="nav-link" id="basic_info_a">
                    <div className="card-body p-0">
                      Basic Info
                      <i className="float-right fas fa-angle-left"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                    {has_access_to('ClientInformation') ? (<li className="nav-item">
                      <NavLink to="/intake-basicinfo" className="nav-link">
                        <div className="card-body p-0">
                          Basic Info
                        </div>
                      </NavLink>
                    </li>) : ''}
                    {has_access_to('EmergencyContact') ? (<li className="nav-item">
                      <NavLink to="/emergencycontact" className="nav-link">
                        <div className="card-body p-0">
                          Emergency Contact
                        </div>
                      </NavLink>
                    </li>) : ''}
                  </ul>
                </li>) : ""}


                <li className="nav-item">
                  <a href="javascript:void(0)" className="nav-link" id="basic_info_a">
                    <div className="card-body p-0">
                      Screening
                      <i className="float-right fas fa-angle-left"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                  { has_access_to('uncope_screen') ? <li className="nav-item">
                      <NavLink to="/uncopeScreen" className="nav-link">
                        <div className="card-body p-0">
                          Uncope Screening
                        </div>
                      </NavLink>
                      {/* <Link to={{ pathname: '/Screening', state: { id: 1, name: 'sabaoon', shirt: 'green' }}} className="pl-3" style={{'text-decoration': 'none', color : '#2aabe4'}} >Learn More</Link> */}
                    </li> : ""
                  }
                  { has_access_to('craft_screen') ? <li className="nav-item">
                      <NavLink to="/craftScreen" className="nav-link">
                        <div className="card-body p-0">
                          Craft Screening
                        </div>
                      </NavLink>
                    </li> : ""
                  }
                  </ul>
                </li>


                {has_access_to('ConsentDetails') ? (<li className="nav-item">
                  <NavLink to="/consent" className="nav-link">
                    <div className="card-body p-0">
                      Consent to Services
                    </div>
                  </NavLink>
                </li>) : ''}

                {has_access_to(['ClientInformation', 'EmergencyContact']) ? (<li className="nav-item">
                  <a href="javascript:void(0)" className="nav-link" id="basic_info_a">
                    <div className="card-body p-0">
                      Release of Information
                      <i className="float-right fas fa-angle-left"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                    {has_access_to('Authorization') ? (<li className="nav-item">
                      <NavLink to="/consent-for-roi" className="nav-link">
                        <div className="card-body p-0">
                          Authorization for ROI
                        </div>
                      </NavLink>
                    </li>) : ''}
                    {has_access_to('HospitalROI') ? (<li className="nav-item">
                      <NavLink to="/verbal-consent" className="nav-link">
                        <div className="card-body p-0">
                          Criminal Justice ROI
                        </div>
                      </NavLink>
                    </li>) : ''}

                    {has_access_to('ConsentForm') ? (<li className="nav-item">
                      <NavLink to="/consent7" className="nav-link">
                        <div className="card-body p-0">
                          Hospital ROI
                        </div>
                      </NavLink>
                    </li>) : ''}
                  </ul>
                </li>) : ""}
                {has_access_to(['OtherAgency', 'HangoutDetails', 'ProbationParoleCasesDetails', 'HomelessnessDetails']) ? (<li className="nav-item">
                  <a href="javascript:void(0)" className="nav-link" id="basic_info_a">
                    <div className="card-body p-0">
                      Other Info
                      <i className="float-right fas fa-angle-left"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                    {has_access_to('OtherAgency') ? (<li className="nav-item">
                      <NavLink to="/otheragency" className="nav-link">
                        <div className="card-body p-0">
                          Other Agencies
                        </div>
                      </NavLink>
                    </li>) : ""}
                    {has_access_to('HangoutDetails') ? (<li className="nav-item">
                      <NavLink to="/hangout" className="nav-link">
                        <div className="card-body p-0">
                          Hangout
                        </div>
                      </NavLink>
                    </li>) : ""}
                    {has_access_to('ProbationParoleCasesDetails') ? (<li className="nav-item">
                      <NavLink to="/cases" className="nav-link">
                        <div className="card-body p-0">
                          Probation/Parole/Cases
                        </div>
                      </NavLink>
                    </li>) : ""}
                    {has_access_to('HomelessnessDetails') ? (<li className="nav-item">
                      <NavLink to="/homelessness" className="nav-link">
                        <div className="card-body p-0">
                          Homelessness
                        </div>
                      </NavLink>
                    </li>) : ""}
                  </ul>

                </li>) : ""}
                {has_access_to(['Assessment', 'SupplementaryInformation']) ? (
                  <li className="nav-item" id="intake_assessment">
                    <a href="javascript:void(0)" className="nav-link">
                      <div className="card-body p-0">
                        Intake Assessment
                        <i className="float-right fas fa-angle-left"></i>
                      </div>
                    </a>
                    <ul className="nav nav-treeview">
                      {has_access_to('Assessment') ? (<li className="nav-item">
                        <NavLink to="/intakeassessment" className="nav-link">
                          <div className="card-body p-0">
                            Assessment
                          </div>
                        </NavLink>
                      </li>) : ""}
                      {has_access_to('SupplementaryInformation') ? (<li className="nav-item">
                        <NavLink to="/supplementaryinfo" className="nav-link">
                          <div className="card-body p-0">
                            Supplementary Information
                          </div>
                        </NavLink>
                      </li>) : ""}
                    </ul>
                  </li>
                ) : ""}
              </ul>
            </div>
          </li>

          {has_access_to('RecoveryGoals') ? (<li className="nav-item">
            <div className="card">
              <NavLink to="/recoverygoals" className="nav-link">
                <div className="card-body p-0">
                  <i className="dcarissidenav fas fa-heart"></i> Recovery Goals
                </div>
              </NavLink>
            </div>
          </li>) : ""}
          {has_access_to('CSSR') ? (<li className="nav-item has-treeview" id="health_screenings">
            <div className="card">
              <NavLink to="/health_screenings" className="nav-link">
                <div className="card-body p-0">
                  <i className="fas fa-stethoscope"></i> Health Screenings
                </div>
              </NavLink>

            </div>
          </li>) : ""}
          {has_access_to(['Followups', 'Notes', 'Referrals', 'RoutingForm', 'ParticipationChangeInformation', 'FinalAssessments']) ? (
            <div className="row sidenav" style={{ paddingLeft: "6px" }}>
              <div className="col-md-12 col-lg-12">
                <div className="text-green"><span>Other Forms</span></div>
              </div>
            </div>) : ""}
          <div className="row">
            <div className="col-md-12 col-lg-6">
              {has_access_to('Followups') ? (<li className="nav-item" id="followup">
                <div className="card">

                  <NavLink to="/followup" className="nav-link">
                    <div className="card-body p-0">
                      <i className="nav-icon fas fa-calendar-check"></i> Follow-Up
                    </div>
                  </NavLink>

                </div>
              </li>) : ""}
            </div>
            <div className="col-md-12 col-lg-6">
              {has_access_to('Notes') ? (<li className="nav-item">
                <div className="card">
                  <NavLink to="/notes" className="nav-link">
                    <div className="card-body p-0">
                      <i className="nav-icon far fa-clipboard"></i> Notes
                    </div>
                  </NavLink>
                </div>
              </li>) : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              {has_access_to('Referrals') ? (<li className="nav-item">
                <div className="card">
                  <NavLink to="/referrals" className="nav-link">
                    <div className="card-body p-0">
                      <i className="nav-icon fas fa-calendar-check"></i> Referrals
                    </div>
                  </NavLink>
                  {/* <a href="javascript:void(0)" className="nav-link">
                    <div className="card-body p-0">
                        <i className="nav-icon fas fa-prescription"></i> Referrals
                    </div>
                  </a> */}
                </div>
              </li>) : ""}
            </div>
            <div className="col-md-12 col-lg-6">
              {has_access_to('RoutingForm') ? (<li className="nav-item">
                <div className="card">
                  <NavLink to="/routing" className="nav-link">
                    <div className="card-body p-0">
                      <i className="nav-icon fas fa-medkit"></i> Routings
                    </div>
                  </NavLink>
                </div>
              </li>) : ""}
            </div>

          </div>
          {has_access_to('ParticipationChangeInformation') ? (<li className="nav-item">
            <div className="card">
              <NavLink to="/change_participation" className="nav-link">
                <div className="card-body p-0">
                  <i className="fas fa-user-md"></i> Change Participation
                </div>
              </NavLink>
            </div>
          </li>) : ""}
          <div className="card">
            {has_access_to('FinalAssessments') ? (<li className="nav-item">

              <NavLink to="/state_assessment" className="nav-link">
                <div className="card-body p-0">
                  <i className="fas fa-clipboard-list"></i> State Assessment
                </div>
              </NavLink>

              {/* <a href="/state_assessment" className="nav-link">
                <div className="card-body p-0">
                <i class="fas fa-clipboard-list"></i> State Assessment
                </div>
              </a> */}
              {/* <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink to="/final_assessment" className="nav-link">
                    <div className="card-body p-0">
                      <i className="fas fa-file-pdf"></i> View history
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/uncopeScreening" className="nav-link">
                    <div className="card-body p-0">
                      <i className="fas fa-file-pdf"></i> Uncope Screening
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a href="javascript:void(0)" className="nav-link">
                    <div className="card-body p-0">
                      Assessment
                      <i className="float-right fas fa-angle-left"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/adultPlacementAssesment" className="nav-link">
                        <div className="card-body p-0">
                          <i className="far fa-meh-rolling-eyes"></i> Acute intoxication
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/bioMedicalCondition" className="nav-link">
                        <div className="card-body p-0">
                        <i className="far fa-head-side-virus"></i> Biomedical conditions
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/behavioralComplications" className="nav-link">
                        <div className="card-body p-0">
                          <i className="far fa-flushed"></i> Emotional, Behavioral Complications
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/readinessChange" className="nav-link">
                        <div className="card-body p-0">
                          <i className="fas fa-running"></i> Readiness to change
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/problemPotential" className="nav-link">
                        <div className="card-body p-0">
                        <i className="fas fa-exclamation-triangle"></i> Problem potential
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/recovery" className="nav-link">
                        <div className="card-body p-0">
                          <i className="fas fa-file-pdf"></i> Recovery/Living Environment
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul> */}
            </li>)
              : ""}
          </div>
        </ul>
      </div>

    );
  }
}

export default Sidenav
