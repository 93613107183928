import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Forgotpass from './Forgotpass';
import Login from './Login';
import Dasboard from './Dasboard';
import Clients from './Clients';
import Logout from './Logout';
import Intakebasicinfo from './Intakebasicinfo.js';
import Emergencycontact from './Emergencycontact';
import Otheragency from './Otheragency';
import Hangout from './Hangout';
import Cases from './Cases';
import Homelessness from './Homelessness';
import Consent from './Consent';
import Intakeassessment from './Intakeassessment';
import Lastnightstay from './Lastnightstay';
import Supplementaryinfo from './Supplementaryinfo';
import Cssr from './Cssr';
import QLESQSF from './QLESQSF';
import PHQ9 from './PHQ9';
import Recoverygoals from './Recoverygoals';
import GadScreening from './GadScreening';
import Followups from './FollowUp';
import Referrals from './Referrals';
import Assessments from './Assessments';
import Notes from './Notes';
import Test from './Test';
import HealthScreening from './HealthScreening';
import ChangeParticipation from './ChangeParticipation';
import Routings from './Routings';
import SessionOut from './SessionOut';
import Authorization from './Authorization';
import Consent_for_ROI from './Consent_for_ROI';
import CallbackToLogin from './CallbackToLogin';
import changePassword from './changePassword';
import Otp from './Otp';
import { customURLGen, can_add } from '../ReusableComponents/urlGenerator'
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom'
import { browserHistory } from 'react-router';
import '../global.js';
import '../globalvars.js';
import { has_access_to } from '../ReusableComponents/urlGenerator';
import VerbalConsent from './VerbalConsent';
import MyClients from "./MyClients/index";
import Consentform7 from './consentform7';
import axios from 'axios';
import UserManagement from './UserManagement/index';
import clientManagement from './ClientManagement';
import UncopeScrrening from './uncopeScrrening';
import AdultPlacementAssesment from './adultPlacementAssesment';
import RequestSupport from './RequestSupport.js';
import Charts from "./Charts";
import CoverReport from "./Charts/CoverReport";
import ReferralReport from "./Charts/ReferralReport";
import ReferralMonthReport from "./Charts/ReferralMonthReport";
import BjaReport from "./Charts/BjaReport";
import CraftScreen from './CraftScreen.js';
import UncopeScreen from './UncopeScreen.js';


import consentForm from './eConsentManagement/eConsentSpecialistManagement/eConsentForm.js';
import eConsent from './eConsentManagement/eConsentAdminManagement/EConsent.js';
import eConsentEditor from './eConsentManagement/eConsentAdminManagement/EConsentEditor.js';
import EConsentEditorUpdate from './eConsentManagement/eConsentAdminManagement/EConsentEditorUpdate.js';
import consentOtp from './eConsentManagement/eConsentClientManagement/otpVerification.js';
import sighInConsent from './eConsentManagement/eConsentClientManagement/signInPage.js';
import PeerEConsent from './eConsentManagement/eConsentSpecialistManagement/peerEconsent.js'


class ProtectedRoute extends Component {
  
  render() {
    const { component: Component, ...props } = this.props;
    return (
      <Route
        {...props}
        render={props => {
          if(localStorage.getItem('__TOKEN__') !== '' && localStorage.getItem('__TOKEN__') !== null && localStorage.getItem('__TOKEN__') !== undefined && sessionStorage.getItem('verified') === "true") {
	          if(this.props.path=='/cover_report' && (global.is_admin || global.is_peer_specialist  )) {
		          return (<><Redirect to='/cover_report' /> <CoverReport {...props} /> </>);
            }else if (this.props.path=='/referral_report' && (global.is_admin ||  global.is_peer_specialist  ) ) {
           	  return (<><Redirect to='/referral_report' /> <ReferralReport {...props} /> </>);
            }else if (this.props.path=='/org_monthly_report' && (global.is_admin || global.is_peer_specialist  ) ) {
           	  return (<><Redirect to='/org_monthly_report' /> <ReferralMonthReport {...props} /> </>);
            }else if (this.props.path=='/admh_report' && (global.is_admin || global.is_peer_specialist  ) ) {
           	  return (<><Redirect to='/admh_report' /> <BjaReport {...props} /> </>);
            }else if (this.props.path=='/chart' && (global.is_admin  || global.is_peer_specialist ) ) {
           	  return (<><Redirect to='/chart' /> <Charts {...props} /> </>);
            }else if ((sessionStorage.getItem('clientId') === '' || sessionStorage.getItem('clientId') === null || sessionStorage.getItem('clientId') === undefined) && ((global.is_staff && this.props.path !== '/myclients') || (!global.staff && ['/intake-basicinfo', '/clients'].filter(path => path === this.props.path).length === 0))) {
                //with empty client id a staff can only access clients page and others can access clients page and add new client page
                //console.log('empty', ['/intake-basicinfo',  '/clients'].filter(path => path === this.props.path).length)
                return (<><Redirect to="/myclients" /> <MyClients {...props} /> </>);
              }else if (global.is_client_inactive && !['/dashboard', '/myclients','/clients'].filter(path => path === this.props.path).length === 0) {
                //anyone who is visiting inactive client can see only dashboard page of client
                //console.log('inactive', !['/dashboard',  '/clients'].filter(path => path === this.props.path).length)
                return (<><Redirect to='/dashboard' /> <Dasboard {...props} /> </>);
              }else if (this.props.component_name && !has_access_to(this.props.component_name)) {
                // except Admin, check all the users permission to access the page
                //console.log('redirected, client active?: ', global.is_client_inactive, ['/dashboard',  '/clients'].filter(path => path === this.props.path), )
                return (<><Redirect to='/intake-basicinfo' /> <Intakebasicinfo {...props} /> </>);
              }else if (sessionStorage.getItem('clientId') !== '' && sessionStorage.getItem('clientId') !== null && sessionStorage.getItem('clientId') !== undefined && this.props.path === '/' && localStorage.getItem('__TOKEN__') !== '' && localStorage.getItem('__TOKEN__') !== null && localStorage.getItem('__TOKEN__') !== undefined) {
                return (<><Redirect to="/myclients" /> <Clients {...props} /> </>);
              }else {
                return (<Component {...props} />)
              }
            }else {
              return (<><Redirect to='/' /> <Login {...props} /> </>);
              // return (<><Redirect to='/cover_report' /> <CoverReport {...props} /> </>);
            }
          }
        }
      />
    )
  }
}

class Main extends Component {
  render() {
    if (localStorage.getItem('__TOKEN__')) {
      var jsonData = { 'apikey': localStorage.getItem('__TOKEN__') };
      var datasend = JSON.stringify(jsonData);
      axios.post(customURLGen("clientList"), datasend).then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
    }
    
    return (
      <Router>
        <Switch>
          <ProtectedRoute exact path='/' component={Login} />
          <Route exact path='/requestsupport' component={RequestSupport} />
          <Route exact path='/forgotpass' component={Forgotpass} />
          <Route exact path='/logout' component={Logout} />
          <ProtectedRoute exact path="/myclients" component={MyClients} component_name={'index'} />
          <Route path="/callback_to_login/:id" render={(props) => <CallbackToLogin {...props} />} />
          <Route exact path='/session_timeout' component={SessionOut} />
          <Route path="/changePassword" component={changePassword} />
          <Route path="/otp" component={Otp} />
          <Route exact path='/userManagement' component={UserManagement} component_name={'UserManagement'} />
          <Route exact path='/clientManagement' component={clientManagement} component_name={'clientManagement'} />
          <Route exact path="/uncopeScreening" component={UncopeScrrening} />
          <Route exact path="/adultPlacementAssesment" component={AdultPlacementAssesment} />
          <ProtectedRoute exact path='/dashboard' component={Dasboard} component_name={'Dasboard'} />
          <ProtectedRoute exact path='/clients' component={Clients} />
          <ProtectedRoute exact path='/intake-basicinfo' component={Intakebasicinfo} component_name={'ClientInformation'} />
          <ProtectedRoute exact path='/emergencycontact' component={Emergencycontact} component_name={'EmergencyContact'} />
          <ProtectedRoute exact path='/otheragency' component={Otheragency} component_name={'OtherAgency'} />
          <ProtectedRoute exact path='/authorization' component={Authorization} component_name={'Authorization'} />
          <ProtectedRoute exact path='/hangout' component={Hangout} component_name={'HangoutDetails'} />
          <ProtectedRoute exact path='/cases' component={Cases} component_name={'ProbationParoleCasesDetails'} />
          <ProtectedRoute exact path='/Homelessness' component={Homelessness} component_name={'HomelessnessDetails'} />
          <ProtectedRoute exact path='/consent' component={Consent} component_name={'ConsentDetails'} />
          <ProtectedRoute exact path='/intakeassessment' component={Lastnightstay} component_name={'Assessment'} />
          <ProtectedRoute exact path="/health_screenings" component={HealthScreening} component_name={'CSSR'} />
          <ProtectedRoute exact path='/supplementaryinfo' component={Supplementaryinfo} component_name={'SupplementaryInformation'} />
          <ProtectedRoute exact path="/routing" component={Routings} component_name={'RoutingForm'} />
          <ProtectedRoute exact path='/cssr' component={Cssr} component_name={'CSSR'} />
          <ProtectedRoute exact path='/qlesqsf' component={QLESQSF} component_name={'QLESQSF'} />
          <ProtectedRoute exact path='/phq9' component={PHQ9} component_name={'PHQ9'} />
          <ProtectedRoute exact path='/recoverygoals' component={Recoverygoals} component_name={'RecoveryGoals'} />
          <ProtectedRoute exact path='/gadscreening' component={GadScreening} component_name={'GADScreeningTool'} />
          <ProtectedRoute exact path='/followup' component={Followups} component_name={'Followups'} />
          <ProtectedRoute exact path='/referrals' component={Referrals} component_name={'Referrals'} />
          <ProtectedRoute exact path='/notes' component={Notes} component_name={'Notes'} />
          <ProtectedRoute exact path='/test' component={Test} component_name={'CSSR'} />
          <ProtectedRoute exact path="/state_assessment" component={Assessments} component_name={'FinalAssessments'} />
          <ProtectedRoute exact path='/change_participation' component={ChangeParticipation} component_name={'ParticipationChangeInformation'} />
          <ProtectedRoute exact path='/consent-for-roi' component={Consent_for_ROI} component_name={'Authorization'} />
          <ProtectedRoute exact path='/verbal-consent' component={VerbalConsent} component_name={'HospitalROI'} />
          <ProtectedRoute exact path='/consent7' component={Consentform7} component_name={'ConsentForm'} />
          <Route exact path="/craftScreen" component={CraftScreen} component_name={'craft_screen'} />
          <Route exact path="/uncopeScreen" component={UncopeScreen} component_name={'uncope_screen'} />

          <Route exact path="/eConsent-from" component={consentForm} component_name={'consentForm'} />
          <Route exact path='/e-consent' component={eConsent} component_name={'eConsent'} />
          <Route exact path='/econsenteditor' component={eConsentEditor} component_name={'eConsentEditor'} />
          <Route exact path='/EConsentEditorUpdate' component={EConsentEditorUpdate} component_name={'EConsentEditorUpdate'} />
          <Route exact path="/consentOtp" component={consentOtp} component_name={'consentOtp'} />
          <Route exact path="/e-consent-signin" component={sighInConsent} component_name={'sighInConsent'} />
          <Route exact path="/peer-econsent" component={PeerEConsent} component_name={'PeerEConsent'} />

          {/*CHIPSS REPORT  */}
          <ProtectedRoute exact path="/cover_report" component={CoverReport} component_name={'CoverReport'}/>{" "}
          <ProtectedRoute exact path="/referral_report" component={ReferralReport} component_name={'ReferralReport'}/>{" "}
          <ProtectedRoute exact path="/chart" component={Charts} component_name={'Charts'}/>{" "}
	        <ProtectedRoute exact path="/admh_report" component={BjaReport} component_name={'BjaReport'}/>{" "}
          <ProtectedRoute exact path="/org_monthly_report" component={ReferralMonthReport} component_name={'ReferralMonthReport'}/>{" "}
          {/* <Route exact path="/printAssesment" component={printAssesment} component_name={'printAssesment'}/>{" "}
          <Route exact path="/copecraftscreening" component={printScreening} component_name={'printScreening'}/>{" "}
          <Route exact path="/printasias" component={Printasias} component_name={'Printasias'}/>{" "}
          <Route exact path="/Printdischarge" component={Printdischarge} component_name={'Printdischarge'}/>{" "}  */}
        </Switch>
      </Router>
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


export default Main;
