import React, { useEffect, useState } from "react";
import Topnav from '../../Topnav';
import Footer from '../../Footer';
import { encryptStorage } from '../../LocalStorageInterceptor';
import { Button, Dropdown, ModalBody } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Patientinfoheader from '../../Patientinfoheader';
import Sidenav from '../../Sidenav';
import '../../../global.js';
import axios from "axios";
import { Alert } from "../../toastify";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import moment from "moment";

const PeerEConsent = () => {

    const [rowData, setRowData] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
    const [searchParams, setSearchParams] = useState({ client_name: "", consent_template: "", client_id : "" });
    const [consentDropdownOptions, setConsentDropdownOptions] = useState([]);

    const columns = [
        { name: 'Client ID', selector: row => row.Client_id, sortable: false },
        { name: 'Client Name', selector: row => row.Full_name, sortable: false },
        { name: 'Consent', selector: row => row.Consent, sortable: false },
        { name: 'Email', selector: row => row.mail, sortable: false },
        { name: 'Phone No.', selector: row => row.Primary_phone, sortable: false },
        { name: 'Sent Date', selector: row => row.sent_date, sortable: false },
        { name: 'Validity Upto', selector: row => row.validityUpTo, sortable: false },
        { name: 'Sent by', selector: row => row.sent_by, sortable: false },
        { name: 'Status', selector: row => row.status, sortable: false },
        // { name: '', selector: (row, index) => <div className="d-flex align-item-center ml-4"><select className="btn btn-secondary btn-sm dropdown-toggle" onChange={(e) => { if (e.target.value === "Esign") { window.location.href = "/eConsentSignIn" } else { } }}><option value="">--Select</option><option value="Esign">eSign</option><option value="resend">Re-Send</option></select></div>, sortable: false }
        {
            name: '', selector: (row, index) => <div className="d-flex align-item-center ml-4" >
                {row.status === "Pending" ?
                    <select style={{ marginLeft: "-7px" }} className="btn btn-secondary btn-sm dropdown-toggle" onChange={(e) => handleStatusChange(e, row)}>
                        <option value="">Select</option>
                        <option value="Esign">eSign</option>
                        <option value="resend">Re-Send</option>
                    </select> : <button className="btn btn-primary" onClick={() => handleDownloadConsent(row)}>Download </button>
                }
            </div>, sortable: false
        }
    ];

    const handleStatusChange = (e, clientData) => {
        if (e.target.value === "Esign") {
            window.location.href = "/e-consent-signin"
        } else if (e.target.value === "resend") {
            resendOtp(clientData);
        }

    }

    const resendOtp = async (clientData) => {
        const data = {
            phone: clientData.Primary_phone,
            client_id: clientData.Client_id,
            email: clientData.mail || "",
            method: clientData.communication_method,
            consent_template : clientData.Consent?._id
        };
        try {
            const response = await axios.post("https://econsent.tactionclients.com/api/peersupport/otpresend", JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }, });
            console.log("Form submitted successfully:", response);
            alert("OTP resent successfully!");
        } catch (error) {
            console.error("Error submitting form:", error.response || error.message);
            alert("Failed to send data.");
        }
    };


   

    const handleDownloadConsent = async (row) => {
        try {
            const { data } = await axios.post( "https://econsent.tactionclients.com/api/client/download",{ consent_template: row.consent_template, client_id: row.Client_id }, 
                { responseType: 'blob' }
            );
    
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.download = `Consent_${row.client_id}.pdf`; 
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download error:", error);
        }
    };


    const getTemplate = async () => {
        try {
            const response = await axios.get("https://econsent.tactionclients.com/consenttemplates/list", { params: { user: sessionStorage.getItem('loggedinUserID') } });
            setConsentDropdownOptions(response.data.consentForms);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }


    useEffect(
        () => {
            getTemplate();
            fetchDataList();
        }, []
    );

    // const getTemplate = async () => {
    //     try {
    //         const response = await axios.get("https://econsent.tactionclients.com/consenttemplates/list", {params: { user: 155 }});
    //         setConsentDropdownOptions(response.data.consentForms);
    //     } catch (error) {
    //         console.error('Error fetching templates:', error);
    //     }
    // }

    const fetchDataList = async () => {
        try {
            const fetchedData = await axios.get('https://econsent.tactionclients.com/api/peersupport/getconsent');
            const mappedData = fetchedData.data.data.map((item) => ({
                Client_id: item.client_id,
                Full_name: item.client_name,
                Consent: item.consent_template?.name,
                consent_template:item.consent_template._id,
                mail: item.email,
                Primary_phone: item.phone,
                sent_date: item.send_date,
                Primary_phone: item.phone,
                validityUpTo: item.validity_date,
                sent_by: item.peer_support_specialist,
                status: item.status,
                communication_method: item.communication_method
            }));
            setRowData(mappedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleApply = (event, picker) => {
        // debugger
        setDateRange({
            startDate: picker.startDate.format("MM/DD/YYYY"),
            endDate: picker.endDate.format("MM/DD/YYYY"),
        });

    };


    const handleSearch = async () => {
        const { startDate, endDate } = dateRange
        const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
        const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

        const payloadData = { ...searchParams, startDate: formattedStartDate, endDate: formattedEndDate }

        try {
            const response = await axios.post('https://econsent.tactionclients.com/api/peersupport/filter', JSON.stringify(payloadData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const mappedData = response.data.data.map((item) => ({
                Client_id: item.client_id,
                Full_name: item.client_name,
                Consent: item.consent_template.name,
                mail: item.email,
                Primary_phone: item.phone,
                sent_date: item.send_date,
                Primary_phone: item.phone,
                validityUpTo: item.validity_date,
                sent_by: item.peer_support_specialist,
                status: item.status,
                communication_method: item.communication_method
            }));
            setRowData(mappedData);
        } catch (error) {
            console.error("Error occurred during POST request:", error); // Log any errors
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target
        setSearchParams((preval) => { return { ...preval, [name]: value } });
    }

    const handleReset = () => {
        fetchDataList();
        setSearchParams({ client_name : "", consent_template : "", client_id : "" });
        setDateRange({ startDate: "", endDate: "" });

    }

    return (

        <>
            {/* <div className="container-fluid"> */}
                <div className="hold-transition sidebar-mini text-sm mt-3">
                    <div className="wrapper">
                        <Topnav />
                        <div className="col-12" >
                        {/* <div className="card-header mt-3" > */}
                            {/* <h4 className="text-green float-left">eConsent Templates</h4> */}
                            {/* <h3 className="card-title" style={{text : "green"}}>eConsent Templates</h3> */}
                            {/* <Button variant="secondary" onClick={() => { }} className="btn btn-success float-right d-flex align-items-center"> */}
                                {/* <h5> <i className="fas fa-user-plus"></i>&nbsp; Create eConsent</h5> */}
                            {/* </Button> */}
                        {/* </div> */}
                        <div className="card card-body mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="form-label">Client ID</label>
                                    <input type="text" className="form-control" placeholder="Enter client id" name="client_id" value={searchParams.client_id} onChange={handleChange} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Client Name</label>
                                    <input type="text" className="form-control" placeholder="Enter client name" name="client_name" value={searchParams.client_name} onChange={handleChange} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Consent Form</label>
                                    <select className="form-control" value={searchParams.consent_template} name="consent_template" onChange={handleChange} >
                                        <option value="">Select</option>
                                        {consentDropdownOptions.map(opt => <option value={opt.name}>{opt.name}</option>)}
                                        {/* <option value="Explicit Consent">Explicit Consent</option>
                                        <option value="Implied Consent">Implied Consent</option> */}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Date Range</label>
                                    <DateRangePicker onApply={handleApply} style={{ width: "calc(140% - -20px)" }}>
                                        <input style={{ backgroundColor: "transparent", width: "100%" }} type="text" className="form-control" placeholder="Select date range" readOnly value={dateRange.startDate && dateRange.endDate ? `${dateRange.startDate} - ${dateRange.endDate}` : ""} />
                                    </DateRangePicker>
                                </div>
                                <div className="col-md-1 mt-4">
                                    <button className="btn btn-success float-left p-2" onClick={handleSearch}> Search </button>
                                    <button className="btn btn-default float-right p-2" onClick={handleReset}> Reset </button>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={rowData}
                                // onRowClicked={this.handleRowClicked}
                                customStyles={global.customTableStyles}
                                progressPending={false}
                                striped={true}
                                // pagination
                                paginationComponentOptions={global.paginationComponentOptions}
                                highlightOnHover
                                pointerOnHover
                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                            // onSort={handleSort}
                            // sortFunction={customSort}
                            />
                        </div>
                        </div>
                        </div>
                        
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default PeerEConsent;
