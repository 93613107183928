/* global CKEDITOR */
import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import Topnav from "../../Topnav";
import Modal from '../../Modals/Modal/index';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import TextAreaAutosize from 'react-textarea-autosize';
import axios from 'axios';


const EConsentEditorUpdate = () => {
    let editorInstance = "";
    const location = useLocation();
    const { id, formData } = location.state
    const [formcreateData, setFormcreateData] = useState(formData)
    const [showNewButton, setShowNewButton] = useState(true);
    const [showCloseButton, setshowCloseButton] = useState(false);
    const [usedVars, setUsedVars] = useState([]);
    const editorRef = useRef(null);
    const [showfieldsAll, setshowfieldsAll] = useState(false);
    const [fieldTypes, setFieldTypes] = useState({});
    const [selectImage, setSelectImage] = useState(false);
    const [show, setShow] = useState(false);
    const [currentClick, setCurrentClick] = useState("");
    const [checkboxCount, setCheckboxCount] = useState({}); // Default to 2 options
    const [checkboxValues, setCheckboxValues] = useState({}); // Stores text values for each checkbox
    const [modalValues, setModalValues] = useState({});
    const [currentDateClick, setCurrentDateClick] = useState("");
    const [formData1, setFormData1] = useState({});
    const [consentContent, setConsentContent] = useState({ consentContent: "" });
    const [keyValuePairs, setKeyValuePairs] = useState({}); // Default key-value pair
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [tempFormats, setTempFormats] = useState({});
    const [rows, setRows] = useState([1]);
    const [rows1, setRows1] = useState([1]);





    const handleModalInputChangeSelect = (e, index, keyword) => {
        const { name, value } = e.target;
        // Update keyValuePairs dynamically
        setKeyValuePairs((prevState) => {
            const updatedPairs = { ...prevState };
            // Initialize if keyword doesn't exist
            if (!updatedPairs[keyword]) {
                updatedPairs[keyword] = [{ key: "", value: "" }];
            }
            // Update specific pair at the given index
            updatedPairs[keyword][index] = { ...updatedPairs[keyword][index], [name]: value };

            return updatedPairs;
        });
    };


    const handleAddKeyValuePair = (keyword) => {
        setKeyValuePairs((prevState) => {
            const updatedPairs = { ...prevState };
            if (!updatedPairs[keyword]) {
                updatedPairs[keyword] = [];
            }
            updatedPairs[keyword].push({ key: "", value: "" }); // Add new key-value pair
            return updatedPairs;
        });
    };

    // Remove the key-value pair at the specified index
    const handleRemoveKeyValuePair = (keyword, index) => {
        setKeyValuePairs((prevState) => {
            const updatedPairs = { ...prevState };
            if (updatedPairs[keyword]?.length > 1) {
                updatedPairs[keyword].splice(index, 1); // Remove the specific pair by index
            }
            return updatedPairs;
        });
    };

    const handleDateTimeChange = (keyword, newDate) => {
        debugger;
        const dateFormat = formData1[keyword]?.options?.[0]?.dateFormat || "MM/DD/YYYY";
        const timeFormat = formData1[keyword]?.options?.[0]?.timeFormat || "h:mm A";

        const formattedDate = newDate ? moment(newDate).format(`${dateFormat} ${timeFormat}`) : "";

        const updatedField = {
            fieldName: keyword,
            type: "date",
            value: formattedDate, // Set formatted date in value
            options: [
                {
                    dateFormat: dateFormat, // Include date format
                    timeFormat: timeFormat, // Include time format
                },
            ],
        };

        setFormData1((prev) => ({ ...prev, [keyword]: updatedField }));
    };


    const handleTempDateFormatChange = (format, keyword) => {
        setTempFormats((prev) => ({ ...prev, [keyword]: { ...prev[keyword], dateFormat: format } }));
    };

    const handleTempTimeFormatChange = (format, keyword) => {
        setTempFormats((prev) => ({ ...prev, [keyword]: { ...prev[keyword], timeFormat: format } }));
    };


    const generateGroups = (keyword) => {
        const groups = [];
        const dropdownValue = fieldTypes[keyword];
        const dropradiocheck = dropdownValue;
        const keywordRows = rows1[keyword] || [1];
        keywordRows.forEach((i, index) => {
            groups.push(
                <div className="row mb-4" key={`${keyword}-${i}`}>
                    <div className="col-1">
                        {dropradiocheck === "radio" ? (
                            <input
                                type="radio"
                                id={`radio-${keyword}-${i}`}
                                name={`radio-group-${keyword}`}
                                checked={formData1[keyword]?.value == modalValues[keyword][i]}
                                value={modalValues[keyword] && modalValues[keyword][i] ? modalValues[keyword][i] : ""}
                                onChange={(e) => handleChangeRadioCheck(e, keyword, i)}
                            />
                        ) : (
                            <input
                                type="checkbox"
                                id={`checkbox-${keyword}-${i}`}

                                checked={
                                    Array.isArray(formData1[keyword]?.value) &&
                                    formData1[keyword]?.value.includes(modalValues[keyword]?.[i])
                                }
                                value={modalValues[keyword] && modalValues[keyword][i] ? modalValues[keyword][i] : ""}
                                onChange={(e) => handleChangeRadioCheck(e, keyword, i)}
                            />
                        )}
                    </div>
                    <div className="col-6">
                        <label htmlFor={`${dropradiocheck}-${keyword}-${i}`} className="ml-2">
                            {checkboxValues[keyword] && checkboxValues[keyword][i]
                                ? checkboxValues[keyword][i]
                                : `Option ${i}`}
                        </label>
                    </div>
                </div>
            );
        });

        return groups;
    };
    const handleChangeRadioCheck = (e, keyword, index) => {
        const value = e.target.value;
        const modalValuesOption = Object.values(modalValues[keyword])

        if (e.target.type === "radio") {
            // For radio buttons
            const updatedFieldRadio = {
                fieldName: keyword,
                type: e.target.type,
                value: value,
                options: modalValuesOption,
            };

            setFormData1((prev) => ({
                ...prev,
                [keyword]: updatedFieldRadio,
            }));
        } else if (e.target.type === "checkbox") {
            // For checkboxes
            setFormData1((prev) => {
                const existingField = prev[keyword] || { fieldName: keyword, type: "checkbox", value: [], options: modalValuesOption };
                const updatedValues = existingField.value.includes(value)
                    ? existingField.value.filter((item) => item !== value) // Remove if already selected
                    : [...existingField.value, value]; // Add if not selected

                return {
                    ...prev,
                    [keyword]: {
                        ...existingField,
                        value: updatedValues,
                    },
                };
            });
        }

        console.log(formData1, "formData1");
    };

    const generateGroups1 = (keyword) => {
        const groups = [];
        const dropdownValue = fieldTypes[keyword];
        const dropradiocheck = dropdownValue;
        const keywordRows = rows[keyword] || [1];

        keywordRows.forEach((i, index) => {
            groups.push(
                <div className="row mb-4" key={`${keyword}-${i}`}>
                    <div className="col-1">
                        {dropradiocheck === "radio" ? (
                            <input
                                type="radio"
                                id={`radio-${keyword}-${i}`}
                                name={`radio-group-${keyword}`}
                                disabled
                            />
                        ) : (
                            <input
                                type="checkbox"
                                id={`checkbox-${keyword}-${i}`}
                                disabled
                            
                            />
                        )}
                    </div>
                    <div className="col-5">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Edit Option ${i}`}
                            value={modalValues[keyword] && modalValues[keyword][i] ? modalValues[keyword][i] : ""}
                            onChange={(e) => handleRadioCheckInputChange(i, e.target.value, keyword)}
                        />
                    </div>
                    {index === 0 ? (
                        <div className="col-1">
                            <i
                                className="material-icons"
                                style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer" }}
                                onClick={() => handleAddClick(keyword)}
                            >
                                add
                            </i>
                        </div>
                    ) : (
                        <div className="col-1">
                            <i
                                className="material-icons"
                                style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer" }}
                                onClick={() => handleRemoveClick(keyword, i)}
                            >
                                remove
                            </i>
                        </div>
                    )}
                </div>
            );
        });

        return groups;
    };

    // Handle Add functionality: Add a new row with unique index
    const handleAddClick = (keyword) => {
        // Ensure rows[keyword] is initialized
        const currentRows = rows[keyword] || [1];

        // Calculate the new row index
        const newRowIndex = currentRows.length ? Math.max(...currentRows) + 1 : 1;

        // Update the rows state
        setRows((prevRows) => ({ ...prevRows, [keyword]: [...currentRows, newRowIndex] }));

        // Update the modalValues state
        setModalValues((prev) => ({ ...prev, [keyword]: { ...(prev[keyword] || {}), [newRowIndex]: "", } }));
    };

    const handleRemoveClick = (keyword, rowIndex) => {
        const updatedRows = rows[keyword].filter((row) => row !== rowIndex);
        setRows((prevRows) => ({ ...prevRows, [keyword]: updatedRows, }));
        setModalValues((prev) => {
            const updatedModalValues = { ...prev };
            delete updatedModalValues[keyword][rowIndex];
            return updatedModalValues;
        });
    };



    const handleRadioCheckInputChange = (index, value, keyword) => {
        setModalValues((prev) => ({ ...prev, [keyword]: { ...(prev[keyword] || {}), [index]: value, } }));
    };


    const handleButtonClick = () => {
        setShowNewButton(false);
        setshowCloseButton(true);
        setshowfieldsAll(true);
    };

    const handleNextButtonClick = () => {
        setShowNewButton(true);
        setshowCloseButton(false);
        setshowfieldsAll(false);
    };

    const handleSelectChange = (e, variable) => {
        setDropdownOptions((prevTypes) => ({ ...prevTypes, [variable]: "" }));
        setFormData1((prevTypes) => ({ ...prevTypes, [variable]: "" }));
        setKeyValuePairs((prevTypes) => ({ ...prevTypes, [variable]: "" }))
        const value = e.target.value;
        setFieldTypes((prevTypes) => ({ ...prevTypes, [variable]: value, }));
        // Update `selectImage` for the specific dropdown
        setSelectImage((prevSelectImage) => ({ ...prevSelectImage, [variable]: !(value === "textarea" || value === "text") }));
        // setCheckboxCount((prevCount) => ({ ...prevCount, [variable]: 2 }));
    };


    const addRowElement = (keyword) => {
        debugger
        // const dropdownValue = fieldTypes[keyword];
        // const dropradiocheck = dropdownValue;
        // if (dropradiocheck === "radio" || dropradiocheck === "checkbox") {
        //     setCurrentDateClick(true)
        // } else {
        //     setCurrentDateClick(false)
        // }
        setShow(true);
        setCurrentClick(keyword);
    }

    const closeModal1 = () => {
        setShow(false);
    }

    const addRadioButton = (keyword) => {
        debugger;
        const dropdownValue = fieldTypes[keyword];
        const dropradiocheck = dropdownValue;
        // start select
        if (dropradiocheck === "select") {
            setDropdownOptions((prevOptions) => {
                const updatedOptions = (keyValuePairs[keyword] || []).filter((pair) => pair.key && pair.value).map((pair) => ({ key: pair.key, value: pair.value }));
                return { ...prevOptions, [keyword]: updatedOptions };
            });

            setDropdownOptions((prevOptions) => {
                const currentOptions = prevOptions[keyword] || [];
                const updatedField = {
                    fieldName: keyword,
                    type: "select",
                    options: currentOptions.map((item) => ({ ...item })),
                    value: "",
                };
                setFormData1((prevFields) => ({ ...prevFields, [keyword]: updatedField }));
                return prevOptions;
            });
        }
        // end select
        // date start
        if (dropradiocheck === "date") {
            const dateFormat = tempFormats[keyword]?.dateFormat || "MM/DD/YYYY";
            const timeFormat = tempFormats[keyword]?.timeFormat || "h:mm A";

            const updatedField = {
                fieldName: keyword,
                type: "date",
                value: formData1[keyword]?.value || "", // Preserve existing value
                options: [
                    {
                        dateFormat: dateFormat,
                        timeFormat: timeFormat,
                    },
                ],
            };

            setFormData1((prevFields) => ({ ...prevFields, [keyword]: updatedField }));
        }
        // date end  
        // radio start
        if (dropradiocheck === "radio" || dropradiocheck === "checkbox") {
            // Get the current options for the keyword
            const modalValuesOption = modalValues[keyword] || {};

            // Add a new option with a blank value
            const newOptionIndex = Object.values(modalValuesOption);
            // Update the formData1 state
            if (dropradiocheck === "radio") {
                // For radio buttons
                const updatedFieldRadio = {
                    fieldName: keyword,
                    type: "radio",
                    value: "", // Blank value initially
                    options: newOptionIndex, // Include updated options
                };

                setFormData1((prev) => ({
                    ...prev,
                    [keyword]: updatedFieldRadio,
                }));
            } else if (dropradiocheck === "checkbox") {
                // For checkboxes
                const updatedFieldCheckbox = {
                    fieldName: keyword,
                    type: "checkbox",
                    value: [], // Empty array initially
                    options: newOptionIndex, // Include updated options
                };

                setFormData1((prev) => ({
                    ...prev,
                    [keyword]: updatedFieldCheckbox,
                }));
            }
        }
        setCheckboxValues(modalValues);
        setRows1((prev) => rows);
        // radio end
        setShow(false);
    };

    
    const getTemplateForm = async () => {
        try {
            const templateid = id;
            const response = await axios.get(`https://econsent.tactionclients.com/consenttemplates/getdetails?id=${templateid}`);
            console.log(response.data, "#####################################")
            const { consentContent, name, vilidity, consentContentDynamicFields, type, description, autoRenewal, user, lastUpdatedBy, client_id } = response.data
            const formCreateData = { name, vilidity, type, description, autoRenewal, user, lastUpdatedBy, client_id, }
            const formEditorData = { consentContentDynamicFields }
            console.log(formEditorData, "formEditorDataformEditorDataformEditorData")
            setFormData1(formEditorData.consentContentDynamicFields)
            setFormcreateData(formCreateData);
            const parser = new DOMParser();
            const doc = parser.parseFromString(consentContent, "text/html");

            const extractedText = doc.body.textContent || "oilkhjgf";

            console.log(extractedText, "extractedTextextractedTextextractedText")
            setConsentContent({ consentContent: extractedText });
            editorInstance.setData(extractedText)
            if (editorRef.current) {
                const editorInstance = CKEDITOR.replace(editorRef.current);
                editorInstance.setData(extractedText);
            }

        } catch (error) {
            console.error("Error fetching consent content:", error);
        }
    };



    const handleInputChange = (e) => {
        setConsentContent({ consentContent: e.target.value });
    };


    useEffect(() => {
        debugger
        if (editorRef.current) {
            editorInstance = CKEDITOR.replace(editorRef.current);
            const handleEditorChange = () => {
                const editorData = editorInstance.getData();

                setConsentContent({ consentContent: editorData });

                // Extract variables inside %% %% format
                if (editorData && editorData.length > 4) {
                    const regex = /%%([\w\s\-\.]*)%%/g;
                    const matches = [...editorData.matchAll(regex)];
                    const vars = {};
                    matches.forEach((match) => {
                        if (match[1]) {
                            vars[match[1]] = true;
                        }
                    });
                    setUsedVars(Object.keys(vars));
                }
            };

            // Handle change and paste events
            editorInstance.on("change", handleEditorChange);
            editorInstance.on("paste", () => {
                setTimeout(handleEditorChange, 100);
            });

            return () => {
                if (CKEDITOR.instances[editorRef.current.id]) {
                    CKEDITOR.instances[editorRef.current.id].destroy(true);
                }
            };
        }
    }, []);


    useEffect(() => {
        getTemplateForm()
    }, [])

    // useEffect(() => {
    //     if (formData1.length > 0) {
    //         formData1.map((item, index) => {
    //             fieldTypes[item.fieldName] = item.type


    //         })
    //         console.log("nitin: " + JSON.stringify(formData1))    
    //     }



    // }, [formData1])

   

    useEffect(() => {
        if (Array.isArray(formData1) && formData1.length > 0) {
            const updatedFieldTypes = {};
            const updatedSelectImage = {};
            const updatedFieldsOptions = {};
            const updatedFieldsRadioCheck = {};
            const updatedRows = {};
            const updatedFieldsDate = {};
            const updatedFieldsValue = {};

            const updatedFormData = {}; // 🆕 Final object to store updated formData1

            formData1.forEach((item) => {
                updatedFieldTypes[item.fieldName] = item.type;
                updatedSelectImage[item.fieldName] = !(item.type === "text" || item.type === "textarea");

                if (item.type === "text" || item.type === "textarea") {
                    updatedFieldsValue[item.fieldName] = item.value;
                }

                if (item.type === "select") {
                    updatedFieldsOptions[item.fieldName] = item.options || [];
                    updatedFieldsValue[item.fieldName] = item.value;

                    updatedFormData[item.fieldName] = {
                        type: item.type, // Include type
                        value: item.value,
                        options: item.options || []
                    };
                } else {
                    updatedFormData[item.fieldName] = {
                        type: item.type, // Include type
                        value: item.value
                    };
                }

                if (item.type === "radio") {
                    updatedFieldsRadioCheck[item.fieldName] = {};
                    updatedRows[item.fieldName] = item.options ? item.options.map((_, i) => i + 0) : [1];

                    item.options.forEach((option, index) => {
                        updatedFieldsRadioCheck[item.fieldName][index] = option;
                    });
                    updatedFieldsValue[item.fieldName] = item.value;

                    updatedFormData[item.fieldName] = {
                        type: item.type, // Include type
                        value: item.value,
                        options: item.options || []
                    };
                } else {
                    updatedFieldsRadioCheck[item.fieldName] = {};
                    updatedRows[item.fieldName] = item.options ? item.options.map((_, i) => i + 0) : [1];

                    item.options.forEach((option, index) => {
                        updatedFieldsRadioCheck[item.fieldName][index] = option;
                    });
                    updatedFieldsValue[item.fieldName] = item.value;

                    updatedFormData[item.fieldName] = {
                        type: item.type, // Include type
                        value: item.value,
                        options: item.options || []
                    };
                }

                if (item.type === "date") {
                    updatedFieldsDate[item.fieldName] = item.options?.[0];
                }
            });

            setFieldTypes(updatedFieldTypes); // Element open according to type
            setSelectImage(updatedSelectImage); // Setting button open
            setKeyValuePairs(updatedFieldsOptions); // Select dropdown option show
            setModalValues(updatedFieldsRadioCheck); // Radio or checkbox modal value show
            setRows(updatedRows);
            setTempFormats(updatedFieldsDate);

            // console.log(updatedFormData, "updatedFieldsValue");
            setFormData1(updatedFormData); // 🆕 Now formData1 will have type, value, and options

            setDropdownOptions(updatedFieldsOptions);
            setCheckboxValues(updatedFieldsRadioCheck);
            setRows1(updatedRows);
        } else {
            console.error("formData1 is not an array or is empty:", formData1);
        }
    }, [formData1]);

    const handleSubmit = async (e) => {
        const consentContentDynamicFields = { consentContentDynamicFields: Object.keys(formData1).map((key) => ({ fieldName: key, ...formData1[key], })) };
        const templateid = id;
        const concatenatedData = { ...formcreateData, ...consentContentDynamicFields, ...consentContent }

        console.log(consentContentDynamicFields, "consentContentDynamicFields");
        console.log(concatenatedData, "setCampleteFormData - Concatenated Data");

        e.preventDefault();
        try {
            const response = await axios.put(`https://econsent.tactionclients.com/consenttemplates/update/${templateid}`, JSON.stringify(concatenatedData),
                {
                    headers: { 'Content-Type': 'application/json', },
                }
            );
            console.log('Form submitted successfully:', response.data);
            alert('Template created successfully!');
        } catch (error) {
            console.error('Error submitting form:', error.response || error.message);
            alert('Failed to send data.');
        }
    };


    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const updatedField = {
            fieldName: name,
            type: type === "select-one" ? "select" : type,
            value: value,
            options: Array.isArray(dropdownOptions[name]) ? dropdownOptions[name].map((item) => ({ ...item })) : [],
        };
        setFormData1((prevState) => ({ ...prevState, [name]: updatedField }));
    };


    return (
        <>

            {console.log(formData1, "formData1formData1formData1formData1formData1")}
            <div className="hold-transition sidebar-mini text-sm  ">
                <div className="wrapper ">
                    <Topnav />
                    <form onSubmit={handleSubmit} id="EConsentEditorUpdate" className="content-wrapper p-5">
                        <div className="card  card-header mt-5 " style={{ border: "1px solid black", borderRadius: "5px", background: "rgb(255 255 255 / 90%)" }}>
                            <div className="text-center mt-5">
                                <label style={{ width: "30vw", marginLeft: "29vw" }} className="form-control"  ><b>{formcreateData?.name !== undefined && formcreateData?.name !== null ? formcreateData.name : ""} </b></label>
                            </div>
                            <div className="mt-2 p-3 " style={{ border: "2px solid black mt" }}>
                                <textarea name="richTextContent" ref={editorRef} value={consentContent.consentContent} onChange={handleInputChange}></textarea>
                                {/* <textarea name="richTextContent" value={consentContent} ref={editorRef} > </textarea> */}
                                {usedVars.length > 0 && showfieldsAll && (
                                    <div >
                                        <div className="box">
                                            <table className="datatable" >
                                                <thead>
                                                </thead>
                                                <tbody >
                                                    {usedVars.length > 0 && showfieldsAll && (
                                                        <div >
                                                            <div className="box">
                                                                <table className="datatable" >
                                                                    <tbody>
                                                                        {usedVars.reduce((resultArray, item, index) => {
                                                                            const chunkIndex = Math.floor(index / 6);
                                                                            if (!resultArray[chunkIndex]) {
                                                                                resultArray[chunkIndex] = []; // Start a new row
                                                                            }
                                                                            resultArray[chunkIndex].push(item);
                                                                            return resultArray;
                                                                        }, [])
                                                                            .map((row, rowIndex) => (
                                                                                <tr key={rowIndex}>
                                                                                    <div className="row " style={{ width: "72vw", marginTop: "50px" }} >
                                                                                        {row.map((keyword, colIndex) => (
                                                                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-4 " key={colIndex}>
                                                                                                <div className="mb-2">
                                                                                                    <label className="form-label ml-4">{keyword}</label>
                                                                                                </div>
                                                                                                <div className="row  ml-4">
                                                                                                    <div className="col-lg-6 col-md-6 col-sm-12" >
                                                                                                        {(!fieldTypes[keyword]) && (
                                                                                                            <input type="text" className="form-control mb-4" readOnly name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""} id="textInput" placeholder="Enter text" />
                                                                                                        )}
                                                                                                        {(fieldTypes[keyword] === "text") && (
                                                                                                            <input type="text" className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || formData1[keyword]} id="textInput" placeholder="Enter text" />
                                                                                                        )}

                                                                                                        {/* {fieldTypes[keyword] === "select" && (
                                                                                                            <div className="form-group">
                                                                                                                <select className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""}  >
                                                                                                                    <option value="">Select Option</option>
                                                                                                                    {(dropdownOptions[keyword] || []).map((option, index) => (
                                                                                                                        <option key={index} value={option.key}>
                                                                                                                            {option.value}
                                                                                                                        </option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                            </div>
                                                                                                        )} */}

                                                                                                        {fieldTypes[keyword] === "select" && (
                                                                                                            <div className="form-group">
                                                                                                                {/* {console.log(formData1[keyword], "formData1[keyword] formData1[keyword]")} */}
                                                                                                                <select
                                                                                                                    className="form-control mb-4"
                                                                                                                    name={keyword}
                                                                                                                    onChange={handleChange}
                                                                                                                    value={formData1[keyword]?.value || ""}
                                                                                                                >
                                                                                                                    <option value="">Select Option</option>
                                                                                                                    {(dropdownOptions[keyword] || formData1[keyword]?.options || []).map((option, index) => (
                                                                                                                        <option key={index} value={option.key}>
                                                                                                                            {option.value}
                                                                                                                        </option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                            </div>
                                                                                                        )}

                                                                                                        {fieldTypes[keyword] === "date" && (
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12">
                                                                                                                        <div className="form-group" >
                                                                                                                            <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                                                                                <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                                                                            </div>
                                                                                                                            <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                                                                                <span style={{ width: '100%' }}>
                                                                                                                                    <DateTime
                                                                                                                                        inputProps={{
                                                                                                                                            value: formData1[keyword]?.value ? moment(formData1[keyword]?.value, `${formData1[keyword]?.options?.[0]?.dateFormat} ${formData1[keyword]?.options?.[0]?.timeFormat}`
                                                                                                                                            ).format(`${formData1[keyword]?.options?.[0]?.dateFormat} ${formData1[keyword]?.options?.[0]?.timeFormat}`)
                                                                                                                                                : "",
                                                                                                                                        }}
                                                                                                                                        onChange={(newDate) => handleDateTimeChange(keyword, newDate)}
                                                                                                                                        dateFormat={formData1[keyword]?.options?.[0]?.dateFormat || "MM/DD/YYYY"}
                                                                                                                                        timeFormat={formData1[keyword]?.options?.[0]?.timeFormat || "h:mm A"}
                                                                                                                                        input={true} // Allow manual entry
                                                                                                                                    />

                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )}
                                                                                                        {fieldTypes[keyword] === "textarea" && (
                                                                                                            <TextAreaAutosize className="form-control" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || formData1[keyword]} id="textInput" placeholder="Enter text"></TextAreaAutosize>
                                                                                                            // <input className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""} id="textInput" placeholder="Enter text" />
                                                                                                        )}
                                                                                                        {fieldTypes[keyword] === "checkbox" && (
                                                                                                            <div className="row mb-4">{generateGroups(keyword)}</div>
                                                                                                        )}

                                                                                                        {fieldTypes[keyword] === "radio" && (
                                                                                                            <div className="row mb-4">{generateGroups(keyword)}</div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-lg-5 col-md-6 col-sm-12">
                                                                                                        <select className="form-control mb-4" value={fieldTypes[keyword] || ""} onChange={(e) => handleSelectChange(e, keyword)} >
                                                                                                            <option value="">Select Type</option>
                                                                                                            <option value="text">Text Input</option>
                                                                                                            <option value="select">Select Dropdown</option>
                                                                                                            <option value="date">Date Input</option>
                                                                                                            <option value="textarea">Text Area</option>
                                                                                                            <option value="radio">Radio Buttons</option>
                                                                                                            <option value="checkbox">Checkboxes</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    {fieldTypes[keyword] && selectImage[keyword] ? (
                                                                                                        <div className="col-lg-1 col-md-6 col-sm-12 d-flex ">
                                                                                                            <i onClick={() => addRowElement(keyword)} className="fa fa-cog  mt-2" aria-hidden="false"></i>
                                                                                                        </div>
                                                                                                    ) : (""
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {show === true ?
                                            <form>
                                                <Modal
                                                    showOverlay={show === true ? true : false}
                                                    // show={this.state.editModal}
                                                    disableOverlayClick={true}
                                                    showCloseButton={false}
                                                    size="lg " // Increased size
                                                    className="modal-lg-dialog modal-md-dialog"
                                                    style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", minWidth: "600px", minheight: '1000px' }}
                                                >
                                                    <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                        <Modal.Title>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <p className="lead float-left" style={{ margin: 0 }}>
                                                                        {usedVars.map((keyword, index) => (
                                                                            <div key={index}>
                                                                                {currentClick == keyword && fieldTypes[keyword] === "date" && (
                                                                                    <div>
                                                                                        <b className="text-green">Field Date and Time</b>
                                                                                    </div>
                                                                                )}
                                                                                {currentClick == keyword && fieldTypes[keyword] === "radio" && (
                                                                                    <div>
                                                                                        <b className="text-green">Field Radio Button</b>
                                                                                    </div>
                                                                                )}
                                                                                {currentClick == keyword && fieldTypes[keyword] === "checkbox" && (
                                                                                    <div>
                                                                                        <b className="text-green">Field Check Box</b>
                                                                                    </div>
                                                                                )}
                                                                                {currentClick == keyword && fieldTypes[keyword] === "select" && (
                                                                                    <div>
                                                                                        <b className="text-green">Field select </b>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </p>
                                                                    <div className="float-right">
                                                                        <button style={{ background: "none" }} onClick={closeModal1} >
                                                                            <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none" }}>
                                                                                cancel
                                                                            </i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <>
                                                            {usedVars.map((keyword, index) => (
                                                                currentClick == keyword ? (
                                                                    <div className="form-group" key={index}>
                                                                        <div className="mb-4">
                                                                            <div >
                                                                                <div className="vw-50">
                                                                                    {fieldTypes[keyword] === "radio" || fieldTypes[keyword] === "checkbox" ? generateGroups1(keyword) : ""}
                                                                                </div>
                                                                                {fieldTypes[keyword] === "select" ?
                                                                                    <div className="row">
                                                                                        <div className="col-10">
                                                                                            {(keyValuePairs[keyword] || [{ key: "", value: "" }]).map((pair, index) => (
                                                                                                <div key={index} className="d-flex mb-2">
                                                                                                    <div className="row">
                                                                                                        <div className="form-group  col-5 w-50 ">
                                                                                                            <label>Key</label>
                                                                                                            <input type="text" name="key" className="form-control" placeholder="Enter key" value={pair.key} onChange={(e) => handleModalInputChangeSelect(e, index, keyword)} />
                                                                                                        </div>
                                                                                                        <div className="form-group  col-5 w-50 ">
                                                                                                            <label>Value</label>
                                                                                                            <input type="text" name="value" className="form-control" placeholder="Enter value" value={pair.value} onChange={(e) => handleModalInputChangeSelect(e, index, keyword)} />
                                                                                                        </div>
                                                                                                        {index === 0 && (
                                                                                                            <div className="col-1">
                                                                                                                <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", marginTop: "27px", }} onClick={() => handleAddKeyValuePair(keyword)} >
                                                                                                                    add
                                                                                                                </i>
                                                                                                            </div>)}
                                                                                                        {index > 0 && ( // Only show remove icon if it's not the first item
                                                                                                            <div className="col-1" >
                                                                                                                <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", marginTop: "27px" }} onClick={() => handleRemoveKeyValuePair(keyword, index)}  >
                                                                                                                    remove
                                                                                                                </i>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    : ""}
                                                                                {
                                                                                    fieldTypes[keyword] === "date" ? <div>
                                                                                        <div className="row">
                                                                                            <div className="col-6">
                                                                                                <h5>Date Format</h5>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="row">
                                                                                                    <div className="col-1">
                                                                                                        <input type="radio" value="MM/DD/YYYY" checked={tempFormats[keyword]?.dateFormat === "MM/DD/YYYY"} onChange={() => handleTempDateFormatChange("MM/DD/YYYY", keyword)} />
                                                                                                    </div>
                                                                                                    <div className="col-11">
                                                                                                        <label>
                                                                                                            MM/DD/YYYY
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-1">
                                                                                                        <input type="radio" value="YYYY-MM-DD" checked={tempFormats[keyword]?.dateFormat === "YYYY-MM-DD"} onChange={() => handleTempDateFormatChange("YYYY-MM-DD", keyword)} />
                                                                                                    </div>
                                                                                                    <div className="col-11">
                                                                                                        <label>
                                                                                                            YYYY-MM-DD
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-1">
                                                                                                        <input type="radio" value="DD/MM/YYYY" checked={tempFormats[keyword]?.dateFormat === "DD/MM/YYYY"} onChange={() => handleTempDateFormatChange("DD/MM/YYYY", keyword)} />
                                                                                                    </div>
                                                                                                    <div className="col-11">
                                                                                                        <label>
                                                                                                            DD/MM/YYYY
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-5">
                                                                                            <div className="col-6">
                                                                                                <h5>Time Format</h5>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="row">
                                                                                                    <div className="col-1">
                                                                                                        <input type="radio" value="h:mm A" checked={tempFormats[keyword]?.timeFormat === "h:mm A"} onChange={() => handleTempTimeFormatChange("h:mm A", keyword)} />
                                                                                                    </div>
                                                                                                    <div className="col-11">
                                                                                                        <label>
                                                                                                            h:mm a (12-hour format )
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-1">
                                                                                                        <input type="radio" value="HH:mm" checked={tempFormats[keyword]?.timeFormat === "HH:mm"} onChange={() => handleTempTimeFormatChange("HH:mm", keyword)} />
                                                                                                    </div>
                                                                                                    <div className="col-11">
                                                                                                        <label>
                                                                                                            HH:mm (24-hour format)
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ("")
                                                            ))}
                                                        </>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                        {usedVars.map((keyword) => (
                                                            currentClick == keyword ? <div>
                                                                <div className='row'>
                                                                    <div className='col-md-12 '>
                                                                        <button type=" " id="nextButton" name='nextButton' onClick={() => addRadioButton(keyword)} className="btn btn-success float-right btnmargin" value="Next >"> OK</button>
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                        ))}
                                                    </Modal.Footer>
                                                </Modal>
                                            </form> : ""
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row ml-3 mb-2 float-right ">
                                <div className="float-right ">
                                    {showNewButton && (
                                        <button id="nextButton" onClick={handleButtonClick} name="nextButton" className="btn btn-success mt-3 float-right  btnmargin" value="Next">
                                            Next
                                        </button>
                                    )}
                                </div>
                            </div>
                            {showfieldsAll === true ?
                                <div className='row'>
                                    <div className=" col-md-6">
                                        {showCloseButton && (
                                            <button id="nextButton" onClick={handleNextButtonClick} name="nextButton" className="btn btn-success  float-left btnmargin" value="Next">
                                                Back
                                            </button>
                                        )}
                                    </div>
                                    <div className='col-md-6 '>
                                        <button type="submit" id="SaveButton" name='SaveButton' style={{ height: "38px", width: "57px" }} className="btn btn-success float-right btnmargin" > Save</button>
                                    </div>
                                </div> : ""
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default EConsentEditorUpdate;