import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import '../global.js';
import { customURLGen, can_add } from '../ReusableComponents/urlGenerator'
import ReactTooltip from 'react-tooltip';
import { date_validate } from '../ReusableComponents/globalFunctions';
import moment from 'moment';
import { Nav, NavDropdown } from "react-bootstrap";
import { encryptStorage } from './LocalStorageInterceptor.js';
//import "../LocalStorageInterceptor"


const axios = require('axios').default;

const reports = [
  // "ADMH-Intake",
  // "ADMH-Residential Waiting List",
  // "ADMH-Entered Tx",
  // "ADMH-Follow up",
  // "ADMH-Discharge Unsuccessful",
  // "ADMH-Discharge Successful",
  // "Field Locations - ADMH Report",
  // "State Required Report",
  "Dashboard",
  "Cover Report",
  "Referral Report",
  //  "RRC Monthly Report",
  "Org Monthly Report",
  "ADMH Report"
];

class Topnav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      top10List: [],
      requests: 0,
      debug: false,
      statusRequest: []
    }
  }

  CryptoJSAesDecrypt = (passphrase, encrypted_json_string) => {
    const CryptoJS = require("crypto-js");
    var obj_json = JSON.parse(encrypted_json_string);
    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  async getStatusSearchResult(event) {
    try {
      var jsonData = { 'apikey': localStorage.getItem('__TOKEN__') };
      jsonData.filters = {};
      if (sessionStorage.getItem('client_search_filter') === 'requested_status') {
        jsonData.filters['client_status'] = ['Activate', 'Inactivate'];
      }
      const selectedOrganization = sessionStorage.getItem('selected_organization');
      if (!selectedOrganization) {
        return;
      } else {
        jsonData.filters['organization'] = selectedOrganization;
      }

      const res = await axios.post(customURLGen("clientList"), JSON.stringify(jsonData));
      const returndata = res.data;
      if (returndata.success && res.status === 200) {
        // Decrypt and set state with the returned data
        this.setState({
          statusRequest: JSON.parse(this.CryptoJSAesDecrypt("tactionData", returndata.data)),
        });
      } else if ("error_code" in returndata) {
        // Handle session timeout or other errors
        console.log("Session Out");
        window.location.href = global.domain + global.traversArray['out'];
      } else {
        alert(returndata.msg); // Show error message from the API
      }
    } catch (err) {
      console.error(err);
    }

    // Move the redirection after the API response handling
    // window.location.href = '/clients';
  }

  componentDidMount() {
    // show status show start
    sessionStorage.setItem('client_search_filter', 'requested_status');
    this.getStatusSearchResult();
     // show status show end
    var apiKey = { apikey: localStorage.getItem('__TOKEN__'), user_id : sessionStorage.getItem('loggedinusername') };
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      if (this.props.organisation === null || this.props.organisation === undefined || this.props.organisation === '') {
        if (sessionStorage.getItem('permissions')) {
          let getPermission = JSON.parse(sessionStorage.getItem('permissions')).orgs_list
          apiKey['organization'] = getPermission[0].org_name;
        }
      } else {
        apiKey['organization'] = this.props.organisation;
      }
    } else {
      apiKey['organization'] = sessionStorage.getItem('selected_organization');
    }
    axios.post(customURLGen("recent10Clients"), JSON.stringify(apiKey))
      .then(async res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          await this.setState({
            top10List: JSON.parse(this.CryptoJSAesDecrypt("tactionData", response.data.clients)),
            requests: response.data.requests
          })
        }
        else if ("error_code" in response) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(response.msg);
        }
      })
      .catch(err => { 
        this.setState({ loading: false, error: true });
        console.error(err,"err check");
        console.log(err.response,"err.responseerr.responseerr.response")
        if (err.response && err.response.status === 401 && err.response.statusText==="Unauthorized") {
          window.location.href = global.domain + global.traversArray['out'];
          alert("Unauthorized user")
        }
      }
    )
  }

  filtered_clients = async () => {
    sessionStorage.setItem('StatusRequestClientsListing', 'Status_requested_status');
    // await sessionStorage.setItem('client_search_filter', 'requested_status');
    // if (sessionStorage.getItem('changes_unsaved')) {
    // } else {
    //   window.location.href = '/clients';
    // }
  }

  clientsPage = () => {
    sessionStorage.removeItem('client_search_filter');
    sessionStorage.removeItem('StatusRequestClientsListing');
    if (sessionStorage.getItem('changes_unsaved')) {
    } else {
      window.location.href = '/clients';
    }
  }

  StatusPage = () => {
    if (sessionStorage.getItem('changes_unsaved')) {
    } else {
      window.location.href = '/clients';
    }
  }

  handleButtonView = (event, rowData) => {
    sessionStorage.setItem('clientId', event.target.id);
    encryptStorage.setItem('client_last_name', rowData.last_name);
    encryptStorage.setItem('ssn', rowData.ssn_no);
    encryptStorage.setItem('gpra_no', rowData.gpra_no);
    encryptStorage.setItem('client_middle_initial', rowData.middle_name);
    encryptStorage.setItem('client_first_name', rowData.first_name);
    encryptStorage.setItem('email', rowData.Primary_email)
    encryptStorage.setItem('phone', rowData.Primary_phone)
    encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
    encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
    encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
    encryptStorage.setItem('last_updated_by', rowData.Last_updated_by == undefined ? rowData.PeerSupportSpecialist : rowData.Last_updated_by);
    encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    encryptStorage.setItem('Address_lns', rowData.Address_lns);
    sessionStorage.setItem('viewMode', 'false');
    encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist);
    sessionStorage.setItem('client_status', rowData.Client_status);
    /*sessionStorage.setItem('last_updated_by', rowData.Last_updated_by);
    sessionStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    sessionStorage.setItem('Address_lns', rowData.Address_lns);
*/
    sessionStorage.setItem('viewMode', 'false');
    if (sessionStorage.getItem('changes_unsaved')) {
    } else {
      window.location.href = '/dashboard';
    }

  };

  generateDropdownOptions() {
    var listOptions = '';
    if (this.state.top10List.length !== 0) {
      listOptions = this.state.top10List.map((item) => {
        return (
          <div>
            <NavLink to={`/basicinfo/id=${item.Client_id}`} className="dropdown-item" id={item.Client_id} onClick={(e) => { this.handleButtonView(e, item) }}> {item.Full_name} </NavLink>
            <div className="dropdown-divider"></div>
          </div>
        );
      });
    }
    else {
      listOptions = <div>
        <a href="#" className="dropdown-item">Your client list is currently empty. <br />Please {can_add('ClientInformation') ? <Link to="intake-basicinfo" onClick={this.clearclientid}>click here</Link> : ''} to add a client </a>
        <div className="dropdown-divider"></div>
      </div>;
    }
    return (listOptions);
  }

  statusRequestDropdownOption() {
    let listOptions = '';
    const { statusRequest } = this.state; // Get the statusRequest data from state

    if (statusRequest && statusRequest.length !== 0) {
      listOptions = statusRequest.map((item) => {
        return (
          <div key={item.Client_id}>
            <NavLink to={`/basicinfo/id=${item.Client_id}`} className="dropdown-item" id={item.Client_id} onClick={(e) => { this.handleButtonView(e, item) }}>
              {item.Full_name}
            </NavLink>
            <div className="dropdown-divider"></div>
          </div>
        );
      });
    } else {
      listOptions = (
        <div>
          <a href="#" className="dropdown-item">
            Your client list is currently empty. <br />
            Please {can_add('ClientInformation') ? <Link to="intake-basicinfo" >click here</Link> : ''} to add a client
          </a>
          <div className="dropdown-divider"></div>
        </div>
      );
    }

    return listOptions;
  }
  

  render() {
    return (
      <div>
        <ReactTooltip place="right" type="dark" effect="solid" />
        <nav className="main-header navbar navbar-expand navbar-green navbar-dark" >
          <a href="/myclients" >
            <img src="/dist/img/dcaris-logo-white.png" alt="DCARIS" style={{ marginTop: "4px", marginBottom: "5px" }} />
          </a> &nbsp; &nbsp;
          <ul className="navbar-nav">
            <li className="nav-item">
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <NavLink to="/myclients" className="nav-link"><h5>Home</h5></NavLink>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <NavLink to="/clients" className="nav-link" onClick={this.clientsPage}><h5>Clients</h5></NavLink>
            </li>
            <li className="nav-item d-none d-sm-inline-block dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <h5>My Recent Clients</h5>
                <span className="badge badge-danger navbar-badge">{this.state.top10List.length}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                {this.generateDropdownOptions()}
                <div><NavLink className="dropdown-item" to="/clients" style={{ textAlign: 'left', color: '#007bff', background: 'transparent' }} onClick={this.clientsPage}> See All Clients </NavLink></div>
              </div>
            </li>

            {global.is_admin || global.is_super_admin ? (
              // <li className="nav-item d-none d-sm-inline-block">
              //   <NavLink to="/AdminRequests" className="nav-link" onClick={this.filtered_clients}><h5>Status Requests</h5>
              //     <span className="badge badge-danger navbar-badge">{this.state.requests}</span></NavLink>
              // </li>
              <li className="nav-item d-none d-sm-inline-block dropdown">
                <a onClick={this.filtered_clients} className="nav-link" data-toggle="dropdown" href="#">
                  <h5>Status Requests</h5>
                  <span className="badge badge-danger navbar-badge">{this.state.requests}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  {this.statusRequestDropdownOption()}
                  <div><NavLink className="dropdown-item" to="/clients" style={{ textAlign: 'left', color: '#007bff', background: 'transparent' }} onClick={this.StatusPage}> See All Clients </NavLink></div>
                </div>
              </li>
              ) : ''
            }

            {global.is_admin || global.is_super_admin ? (
              <>
                <li className="nav-item d-none d-sm-inline-block">
                  <a className="nav-link" target='_blank' href="https://login.microsoftonline.com">
                    <h5>Reports</h5>
                  </a>
                </li>
              </>
            ) : ""}


              <>
                <li className="nav-item d-none d-sm-inline-block">
                  <a className="nav-link" href="/peer-econsent">
                    <h5>eConsent</h5>
                  </a>
                </li>
              </>

           

          </ul>
          {/*<form className="form-inline ml-3">
            <div className="input-group input-group-sm">
            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
          */}
          {/* Report Code */}

          <ul className="navbar-nav ml-auto">

            <li className="nav-item d-none d-sm-inline-block">
              <a href="/requestsupport" className="nav-link"><span>Get Support</span></a>
              {/* onClick={this.clientsPage} */}
            </li>

            {/* <li className="nav-item d-none d-sm-inline-block">
              <Link to="/requestsupport" className="nav-link">
                <span>Get Support</span>
              </Link>
            </li> */}

            {this.state.debug ? (
              <li>
                {global.is_staff
                  ? "Staff"
                  : global.is_admin
                    ? "Admin"
                    : global.is_peer_specialist
                      ? "Peer"
                      : "Clinical"}
              </li>
            ) : (
              ""
            )}

            {global.is_admin || global.is_super_admin ? (
              <li className="nav-item d-none d-sm-inline-block">
                <a href="/userManagement" className="nav-link">
                  <span>Admin </span>
                </a>
                {/* <NavLink to="/userManagement" className="nav-link"><h5>Admin</h5></NavLink> */}
              </li>
            ) : (
              ""
            )}
            <li className="nav-item">
              <a className="nav-link" href="/changePassword">Change Password</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/logout">
                <i className="fas fa-sign-out-alt"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Topnav
